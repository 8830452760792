import React from 'react';
import { useParams } from 'react-router-dom';
import { Text, Stack, Spinner } from 'ui';
import { useGetResourcesQuery } from 'modules/resources/resourcesApi';
import ResourceCard from 'modules/resources/components/resourceCard';

const Resources: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetResourcesQuery(id as string);

  const resources = data?.results;

  if (isLoading) {
    return (
      <Stack justify="center" align="center" fluid fullHeight>
        <Spinner />
      </Stack>
    );
  }

  if (resources?.length) {
    return (
      <Stack gap="12px">
        {resources.map((resource) => (
          <ResourceCard key={resource.id} {...resource} />
        ))}
      </Stack>
    );
  }

  return (
    <Stack align="center" justify="center" style={{ height: '50%' }}>
      <Text size="lg">No Resources...</Text>
    </Stack>
  );
};

export default Resources;
