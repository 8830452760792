import React, { FC } from 'react';
import { MessagePayload } from 'firebase/messaging';
import { Text, Stack } from 'ui';
import colors from 'theme/colors';

interface IProps {
  messagePayload: MessagePayload;
}

const NotificationPopUp: FC<IProps> = ({ messagePayload }) => {
  const { notification } = messagePayload;
  return (
    <Stack gap="12px">
      <Text weight="700" size="sm" color={colors.white}>
        {notification?.title}
      </Text>
      <Text weight="500" size="xs" color={colors.white}>
        {notification?.body}
      </Text>
    </Stack>
  );
};

export default NotificationPopUp;
