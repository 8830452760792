export const mediaSize = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  mobileXL: 500,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

export default {
  mobileS: `(min-width: ${mediaSize.mobileS}px)`,
  mobileM: `(min-width: ${mediaSize.mobileM}px)`,
  mobileL: `(min-width: ${mediaSize.mobileL}px)`,
  mobileXL: `(min-width: ${mediaSize.mobileXL}px)`,
  tablet: `(min-width: ${mediaSize.tablet}px)`,
  laptop: `(min-width: ${mediaSize.laptop}px)`,
  laptopL: `(min-width: ${mediaSize.laptopL}px)`,
  desktop: `(min-width: ${mediaSize.desktop}px)`,
};
