import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { Group, Icon, Text } from 'ui';
import { EEvents, EVENTS_NAME_MAP } from 'constants/events';

interface IProps {
  event: EEvents;
  showText?: boolean | null;
  style?: CSSProperties | undefined;
  size?: string;
}

const Wrapper = styled(Group)`
  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.blue5};
  padding: 8px 12px;
`;

export const EVENTS_ICON_MAP = {
  [EEvents.ISSUE]: <Icon icon="IssueEventIcon" size="small" />,
  [EEvents.QUESTION]: <Icon icon="QuestionEventIcon" size="small" />,
  [EEvents.INSIGHT]: <Icon icon="InsightEventIcon" size="small" />,
  [EEvents.DECISION]: <Icon icon="DecisionEventIcon" size="small" />,
  [EEvents.RESOLUTION]: <Icon icon="ResolutionEventIcon" size="small" />,
  [EEvents.ACTION]: <Icon icon="ActionEventIcon" size="small" />,
  [EEvents.STOPPED_STALLED]: <Icon icon="StoppedEventIcon" size="small" />,
  [EEvents.END]: <Icon icon="EndEventIcon" size="small" />,
};

export const EVENTS_KEY_MAP = {
  [EEvents.ISSUE]: 'IssueEventIcon',
  [EEvents.QUESTION]: 'QuestionEventIcon',
  [EEvents.INSIGHT]: 'InsightEventIcon',
  [EEvents.DECISION]: 'DecisionEventIcon',
  [EEvents.RESOLUTION]: 'ResolutionEventIcon',
  [EEvents.ACTION]: 'ActionEventIcon',
  [EEvents.STOPPED_STALLED]: 'StoppedEventIcon',
  [EEvents.END]: 'EndEventIcon',
};

const EventBadge: FC<IProps> = ({ event, showText = true, style }) => {
  return (
    <Wrapper style={style}>
      {EVENTS_ICON_MAP[event]}
      {showText && <Text>{EVENTS_NAME_MAP[event]}</Text>}
    </Wrapper>
  );
};

export default EventBadge;
