import React, { FC, useState } from 'react';
import PhInput, { CountryData } from 'react-phone-input-2';
import { FieldError, Controller, Control } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';

import { StyledLabel } from 'layout/App';
import colors from 'theme/colors';
import Stack from '../stack';
import Text from '../text';
import './styles.css';

const style = {
  backgroundColor: colors.dark2,
  color: colors.gray1,
  fontSize: '14px',
  lineHeight: '21px',
  border: '1px solid transparent',
  height: 'auto',
  padding: '10px 16px 10px 48px',
  width: 'auto',
};

const containerStyle = {
  borderRadius: '8px',
  backgroundColor: colors.dark2,
  padding: 0,
};

const dropdownStyle = {
  background: colors.dark2,
  border: 'none',
  color: 'white',
};

interface IPhoneInputProps {
  label?: string;
  name: string;
  control: Control<any, any>;
  error?: FieldError;
}

const PhoneInput: FC<IPhoneInputProps> = ({ label, name, error, control }) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <Stack fluid>
      {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, onChange, ...field } }) => (
          <PhInput
            excludeCountries={['ru']}
            containerStyle={{
              ...containerStyle,
              border: `1px solid ${error ? 'red' : 'transparent'}`,
              boxShadow: isFocused ? `0 0 4px ${colors.blue}` : 'none',
            }}
            specialLabel=""
            inputStyle={{ ...style, width: '100%' }}
            country="us"
            dropdownStyle={dropdownStyle}
            inputProps={{
              ref,
              onFocus: () => setIsFocused(true),
              onBlur: () => setIsFocused(false),
            }}
            onChange={(val, data: CountryData) =>
              onChange(`${data?.countryCode?.toUpperCase()} +${val}`)
            }
            {...field}
            value={field.value || ''}
          />
        )}
      />
      {error && (
        <Text size="xs" component="span" color="red">
          {error.message}
        </Text>
      )}
    </Stack>
  );
};
export default PhoneInput;
