import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IFilterState } from 'modules/threads/types';

export const initialState: IFilterState = {
  search: '',
  status: '',
  group: [],
  tags: [],
  date: [],
};

const filterSlice = createSlice({
  name: 'threadsFilter',
  initialState,
  reducers: {
    updateFilter: (state, { payload }: PayloadAction<IFilterState>) => ({
      ...state,
      ...payload,
    }),
    resetFilter: () => initialState,
  },
});

export const selectFilter = (state: RootState) => state.threadsFilter;
export const { updateFilter, resetFilter } = filterSlice.actions;

export default filterSlice.reducer;
