import { useEffect, useState } from 'react';
import { safeJSONParse } from 'utils/helpers';

const useLocalStorage = (storageKey: string, fallbackState?: any) => {
  const initialValue = localStorage.getItem(storageKey);
  const [value, setValue] = useState(initialValue ? safeJSONParse(initialValue) : fallbackState);

  useEffect(() => {
    if (value === null) {
      localStorage.removeItem(storageKey);
      return;
    }
    if (value !== undefined) {
      localStorage.setItem(storageKey, JSON.stringify(value));
    }
  }, [value, storageKey]);

  return [value, setValue];
};

export default useLocalStorage;
