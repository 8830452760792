import { EThreadParams } from 'types';

export const THREAD_TABLE_OPTIONS = [
  EThreadParams.STATUS,
  EThreadParams.NAME,
  EThreadParams.TAGS,
  EThreadParams.IS_PRIVATE,
  EThreadParams.UPDATED_AT,
  // EThreadParams.LAST_ACTIVITY,
  // EThreadParams.GROUP,
  // EThreadParams.DESCRIPTION,
  // EThreadParams.UPDATED_AT,
];

export const AVOID_SORTING_OPTIONS = [
  EThreadParams.DESCRIPTION,
  EThreadParams.TAGS,
  EThreadParams.METRICS,
  EThreadParams.IS_PRIVATE,
];
