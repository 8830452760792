import styled from 'styled-components';
import colors from 'theme/colors';
import { ITextInputProps } from './types';

export const StyledInput = styled.input<ITextInputProps>`
  border: ${({ error }) => `1px solid ${error ? 'red' : 'transparent'}`};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.dark2};
  padding: 12px 45px 12px 16px;
  color: white;
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  background-color: ${({ backgroundColor }) => backgroundColor || colors.dark2};

  &:disabled {
    background-color: ${({ theme }) => theme.colors.dark1};
    color: ${({ theme }) => theme.colors.gray1};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 4px ${({ theme }) => theme.colors.blue};
  }
`;
