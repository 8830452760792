import { removeTimeFromDate, getNextDay } from 'utils/helpers';
import { THREAD_STATUS_COLORS } from 'constants/index';
import { STATIC_GANTT_PROPS } from 'modules/threads/constants';
import { IGanttResult } from 'modules/threads/types';

export const createGanttTask = (thread: IGanttResult) => {
  const relatedThreads = thread.relatedThreads.map((id) => id.toString());
  const start = removeTimeFromDate(thread.createdAt);
  const end = getNextDay(removeTimeFromDate(thread.updatedAt));

  return {
    ...thread,
    id: thread.id.toString(),
    dependencies: relatedThreads,
    start: new Date(start),
    end,
    styles: {
      backgroundColor: THREAD_STATUS_COLORS[thread.status],
      backgroundSelectedColor: THREAD_STATUS_COLORS[thread.status],
    },
    ...STATIC_GANTT_PROPS,
  };
};
