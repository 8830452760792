import React from 'react';
import ScreenContent from '../screenContent';
import Group from '../group';
import Spinner, { TSpinnerSize } from '../spinner';

interface IProps {
  size?: TSpinnerSize;
}
const ScreenLoader = ({ size = 'large' }: IProps) => {
  return (
    <ScreenContent>
      <Group style={{ flex: 1 }} justify="center" align="center">
        <Spinner size={size} />
      </Group>
    </ScreenContent>
  );
};

export default ScreenLoader;
