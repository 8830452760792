import colors from 'theme/colors';

export const GanttStyles = {
  barProgressColor: 'transparent',
  barCornerRadius: 4,
  barFill: 70,
  fontSize: '15px',
  arrowColor: colors.orange,
  todayColor: 'none',
  rowHeight: 55,
  headerHeight: 55,
};
