import { api } from 'app/api';
import {
  IHasAccessMutationPayload,
  IHasAccessMutationResponse,
  IIntegrationsResponse,
  ISiteSubscriptionResponse,
  IUpdateIntegrationMutation,
} from './types';

export const integrationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserAvailableIntegrations: build.query<IIntegrationsResponse, void>({
      query: () => ({
        url: `sites/integrations/`,
      }),
      keepUnusedDataFor: 2,
      providesTags: ['userAvailableIntegrations'],
    }),
    getCurrentSiteSubscription: build.query<ISiteSubscriptionResponse, void>({
      query: () => ({
        url: `sites/subscription-info/`,
      }),
      keepUnusedDataFor: 2,
      providesTags: ['currentSiteSubscription'],
    }),
    getNonActiveIntegrations: build.query<IUpdateIntegrationMutation, void>({
      query: () => ({
        url: `integrations/not-active/`,
      }),
      keepUnusedDataFor: 2,
      providesTags: ['userNonActiveIntegrations'],
    }),
    updateIntegrations: build.mutation<void, IUpdateIntegrationMutation>({
      query: (integration) => ({
        url: `integrations/update/`,
        method: 'PATCH',
        body: integration,
      }),
      invalidatesTags: ['userAvailableIntegrations', 'userNonActiveIntegrations'],
    }),
    sendGoogleAccessToken: build.mutation<void, string>({
      query: (code) => ({
        url: `auth/google-connect/`,
        method: 'POST',
        body: { code },
      }),
      invalidatesTags: ['userAvailableIntegrations'],
    }),
    checkForAccessToTheDocument: build.mutation<
      IHasAccessMutationResponse,
      IHasAccessMutationPayload
    >({
      query: (payload) => ({
        url: `/integrations/has-access/`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetUserAvailableIntegrationsQuery,
  useSendGoogleAccessTokenMutation,
  useCheckForAccessToTheDocumentMutation,
  useUpdateIntegrationsMutation,
  useGetNonActiveIntegrationsQuery,
  useLazyGetCurrentSiteSubscriptionQuery,
} = integrationApi;
