import React, { FC, useState } from 'react';
import { Button, Group, Icon, Text } from 'ui';
import colors from 'theme/colors';
import { IMember } from 'ui/memberList/types';
import Menu from 'ui/menu';
import Member from 'ui/memberList/Member';

interface ILikeProps {
  isActive: boolean;
  count: number;
  onClick?: () => void;
  users: IMember[];
}

const Like: FC<ILikeProps> = ({ isActive, count, onClick, users }) => {
  const [isHover, setHover] = useState(false);
  return (
    <Group align="center">
      <Button
        onClick={onClick}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        textColor={colors.blue8}
        variant="plain"
        cypressAttribute="like-btn"
      >
        <Icon icon={isActive ? 'LikeFilledIcon' : 'LikeIcon'} />
      </Button>
      <Menu
        disabled={!isHover || !users.length}
        data={users}
        Item={Member}
        cypressAttribute="like-members-menu"
      >
        <Text cypressAttribute="like-members-count" color={colors.blue8}>
          {count}
        </Text>
      </Menu>
    </Group>
  );
};

export default Like;
