import styled from 'styled-components';
import colors from 'theme/colors';
import { Button, Group } from 'ui';

export const Card = styled(Group)`
  position: relative;
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.dark2};
`;

export const RemoveButton = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
`;
