import React, { FC } from 'react';
import styled from 'styled-components';
import { handleDownloadFile, isImage } from 'utils/helpers';
import File from './File';

interface IFileViewProps {
  name: string;
  file?: string;
  url?: string;
}

const ImageContainer = styled.div`
  border-radius: 4px;
  & img {
    max-width: 250px;
  }
`;

const FileView: FC<IFileViewProps> = ({ name, file }) => {
  if (file) {
    return (
      <a
        href={file}
        target="_blank"
        rel="noreferrer"
        onClick={isImage(file) ? () => null : handleDownloadFile(name, file)}
      >
        {isImage(file) ? (
          <ImageContainer>
            <img src={file} alt={name} />
          </ImageContainer>
        ) : (
          <File name={name} />
        )}
      </a>
    );
  }
  return null;
};

export default FileView;
