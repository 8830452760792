import { ESystemMessageType } from '../../types';

export const ACTION_MAP = {
  [ESystemMessageType.EVENT]: {
    title: 'Edit an Event',
    modal: 'createEvent',
    getContext: (data: any) => ({
      threadId: data.thread,
      eventId: data.id,
    }),
  },
  [ESystemMessageType.REFERENCE]: {
    title: 'Edit Reference',
    modal: 'editReference',
    getContext: (data: any) => ({ reference: data }),
  },
  [ESystemMessageType.CONTRIBUTOR]: {
    title: 'Edit Contributor',
    modal: 'editContributor',
    getContext: (data: any) => data,
  },
  [ESystemMessageType.RESOURCE]: {
    title: 'Edit Resource',
    modal: 'createResource',
    getContext: (data: any) => ({ threadId: data.thread, initialValue: data }),
  },
};

export const CREATED = 'created';
export const UPDATED = 'updated';
