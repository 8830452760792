export enum ERoles {
  // User with admin privileges
  OWNER = 'OWNER',
  // User able to contribute messages, events etc + invite new members
  CONTRIBUTOR = 'CONTRIBUTOR',
  // Able to contribute only
  GUEST_CONTRIBUTOR = 'GUEST_CONTRIBUTOR',
  // Not able to contribute at all
  VIEW_ONLY = 'VIEW_ONLY',
}

export enum EPermissions {
  EDIT_THREAD = 'EDIT_THREAD',
  DELETE_THREAD = 'DELETE_THREAD',
  ADD_TAG_FROM_THREAD_PAGE = 'ADD_TAG_FROM_THREAD_PAGE',
  VIEW_THREAD = 'VIEW_THREAD',
  VIEW_CHAT = 'VIEW_CHAT',
  SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE',
  EDIT_OWN_CHAT_MESSAGE = 'EDIT_OWN_CHAT_MESSAGE',
  DELETE_OWN_CHAT_MESSAGE = 'DELETE_OWN_CHAT_MESSAGE',
  SEND_ATTACHMENTS_IN_CHAT = 'SEND_ATTACHMENTS_IN_CHAT',
  VIEW_EVENTS = 'VIEW_EVENTS',
  ADD_EVENT = 'ADD_EVENT',
  EDIT_EVENT = 'EDIT_EVENT',
  DELETE_EVENT = 'DELETE_EVENT',
  VIEW_CONTRIBUTORS = 'VIEW_CONTRIBUTORS',
  ADD_CONTRIBUTORS = 'ADD_CONTRIBUTORS',
  DELETE_CONTRIBUTORS = 'DELETE_CONTRIBUTORS',
  VIEW_REFERENCES = 'VIEW_REFERENCES',
  ADD_REFERENCES = 'ADD_REFERENCES',
  EDIT_REFERENCES = 'EDIT_REFERENCES',
  DELETE_REFERENCES = 'DELETE_REFERENCES',
  VIEW_RESOURCES = 'VIEW_RESOURCES',
  ADD_RESOURCES = 'ADD_RESOURCES',
  EDIT_RESOURCES = 'EDIT_RESOURCES',
  DELETE_RESOURCES = 'DELETE_RESOURCES',
  VIEW_RELATED_THREADS = 'VIEW_RELATED_THREADS',
  OPEN_RELATED_THREADS = 'OPEN_RELATED_THREADS',
  ADD_RELATED_THREADS = 'ADD_RELATED_THREADS',
  DELETE_RELATED_THREADS = 'DELETE_RELATED_THREADS',
  DEBUG_MODE = 'DEBUG_MODE',
}

export const PERMISSIONS_PER_ROLE = {
  [ERoles.OWNER]: [
    EPermissions.EDIT_THREAD,
    EPermissions.DELETE_THREAD,
    EPermissions.ADD_TAG_FROM_THREAD_PAGE,
    EPermissions.VIEW_THREAD,
    EPermissions.VIEW_CHAT,
    EPermissions.SEND_CHAT_MESSAGE,
    EPermissions.EDIT_OWN_CHAT_MESSAGE,
    EPermissions.DELETE_OWN_CHAT_MESSAGE,
    EPermissions.SEND_ATTACHMENTS_IN_CHAT,
    EPermissions.VIEW_EVENTS,
    EPermissions.ADD_EVENT,
    EPermissions.EDIT_EVENT,
    EPermissions.DELETE_EVENT,
    EPermissions.VIEW_CONTRIBUTORS,
    EPermissions.ADD_CONTRIBUTORS,
    EPermissions.DELETE_CONTRIBUTORS,
    EPermissions.VIEW_REFERENCES,
    EPermissions.ADD_REFERENCES,
    EPermissions.EDIT_REFERENCES,
    EPermissions.DELETE_REFERENCES,
    EPermissions.VIEW_RESOURCES,
    EPermissions.ADD_RESOURCES,
    EPermissions.EDIT_RESOURCES,
    EPermissions.DELETE_RESOURCES,
    EPermissions.VIEW_RELATED_THREADS,
    EPermissions.OPEN_RELATED_THREADS,
    EPermissions.ADD_RELATED_THREADS,
    EPermissions.DELETE_RELATED_THREADS,
    EPermissions.DEBUG_MODE,
  ],
  [ERoles.CONTRIBUTOR]: [
    EPermissions.VIEW_THREAD,
    EPermissions.VIEW_CHAT,
    EPermissions.SEND_CHAT_MESSAGE,
    EPermissions.EDIT_OWN_CHAT_MESSAGE,
    EPermissions.DELETE_OWN_CHAT_MESSAGE,
    EPermissions.SEND_ATTACHMENTS_IN_CHAT,
    EPermissions.VIEW_EVENTS,
    EPermissions.ADD_EVENT,
    EPermissions.EDIT_EVENT,
    EPermissions.DELETE_EVENT,
    EPermissions.VIEW_CONTRIBUTORS,
    EPermissions.VIEW_REFERENCES,
    EPermissions.ADD_REFERENCES,
    EPermissions.EDIT_REFERENCES,
    EPermissions.DELETE_REFERENCES,
    EPermissions.VIEW_RESOURCES,
    EPermissions.ADD_RESOURCES,
    EPermissions.EDIT_RESOURCES,
    EPermissions.DELETE_RESOURCES,
    EPermissions.VIEW_RELATED_THREADS,
    EPermissions.OPEN_RELATED_THREADS,
    EPermissions.ADD_RELATED_THREADS,
    EPermissions.DELETE_RELATED_THREADS,
    EPermissions.ADD_CONTRIBUTORS,
  ],
  [ERoles.GUEST_CONTRIBUTOR]: [
    EPermissions.VIEW_THREAD,
    EPermissions.VIEW_CHAT,
    EPermissions.SEND_CHAT_MESSAGE,
    EPermissions.EDIT_OWN_CHAT_MESSAGE,
    EPermissions.DELETE_OWN_CHAT_MESSAGE,
    EPermissions.SEND_ATTACHMENTS_IN_CHAT,
    EPermissions.VIEW_EVENTS,
    EPermissions.ADD_EVENT,
    EPermissions.EDIT_EVENT,
    EPermissions.DELETE_EVENT,
    EPermissions.VIEW_CONTRIBUTORS,
    EPermissions.VIEW_REFERENCES,
    EPermissions.ADD_REFERENCES,
    EPermissions.EDIT_REFERENCES,
    EPermissions.DELETE_REFERENCES,
    EPermissions.VIEW_RESOURCES,
    EPermissions.ADD_RESOURCES,
    EPermissions.EDIT_RESOURCES,
    EPermissions.DELETE_RESOURCES,
    EPermissions.VIEW_RELATED_THREADS,
    EPermissions.OPEN_RELATED_THREADS,
    EPermissions.ADD_RELATED_THREADS,
    EPermissions.DELETE_RELATED_THREADS,
  ],
  [ERoles.VIEW_ONLY]: [
    EPermissions.VIEW_THREAD,
    EPermissions.VIEW_CHAT,
    EPermissions.VIEW_EVENTS,
    EPermissions.VIEW_CONTRIBUTORS,
    EPermissions.VIEW_REFERENCES,
    EPermissions.VIEW_RESOURCES,
    EPermissions.VIEW_RELATED_THREADS,
    EPermissions.OPEN_RELATED_THREADS,
  ],
};
