import React, { FC } from 'react';
import styled from 'styled-components';
import { Text, Group, Icon } from 'ui';
import { EThreadPriority } from 'types';
import { PRIORITY_COLORS, PRIORITY_NAMES } from './constants';

interface IProps {
  priority: EThreadPriority;
  cypressAttribute?: string;
}

const Wrapper = styled(Group)<IProps>`
  padding: 4px 8px;
  border-radius: 24px;
  background-color: ${({ priority }) => PRIORITY_COLORS[priority].bg};
`;

const PriorityBadge: FC<IProps> = ({ priority, cypressAttribute }) => {
  return (
    <Wrapper align="center" priority={priority} data-cy={cypressAttribute}>
      <Icon size="small" icon="FlagIcon" stroke={PRIORITY_COLORS[priority].text} />
      <Text
        weight="500"
        color={PRIORITY_COLORS[priority].text}
        cypressAttribute={cypressAttribute && `${cypressAttribute}-text`}
      >
        {PRIORITY_NAMES[priority]}
      </Text>
    </Wrapper>
  );
};

export default PriorityBadge;
