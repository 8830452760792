import styled from 'styled-components';

export const Card = styled.div`
  position: relative;
  padding: 16px 8px 16px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.dark2};

  p {
    flex: 1;
  }
`;

export const SearchBar = styled.div`
  position: relative;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.dark2};
`;

export const SearchIcon = styled.i`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  z-index: 2;
`;

export const DropdownContainer = styled.div`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.dark8};
`;

export const DropdownTitle = styled.div`
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px 8px;
  min-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const Dropdown = styled.div`
  position: relative;
  z-index: 2;
`;

export const DropdownList = styled.ul`
  background-color: ${({ theme }) => theme.colors.dark8};
  width: 414px;
  padding: 20px 8px 8px;
  position: absolute;
  right: 0;
  top: -1px;
  transition: all 0.5 ease-in-out;
`;

export const DropdownOption = styled.li`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
`;
