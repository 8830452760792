import colors from 'theme/colors';

export enum EEvents {
  ISSUE = 'ISSUE',
  QUESTION = 'QUESTION',
  INSIGHT = 'INSIGHT',
  DECISION = 'DECISION',
  RESOLUTION = 'RESOLUTION',
  ACTION = 'ACTION',
  STOPPED_STALLED = 'STOPPED_STALLED',
  END = 'END',
}

export const EVENTS_NAME_MAP = {
  [EEvents.ISSUE]: 'Issue',
  [EEvents.QUESTION]: 'Question',
  [EEvents.INSIGHT]: 'Insight',
  [EEvents.DECISION]: 'Decision',
  [EEvents.RESOLUTION]: 'Resolution',
  [EEvents.ACTION]: 'Action',
  [EEvents.STOPPED_STALLED]: 'Stopped/Stalled',
  [EEvents.END]: 'End',
};

export const EVENTS_OPTIONS = [
  { optionLabel: EVENTS_NAME_MAP[EEvents.ISSUE], value: EEvents.ISSUE },
  { optionLabel: EVENTS_NAME_MAP[EEvents.QUESTION], value: EEvents.QUESTION },
  { optionLabel: EVENTS_NAME_MAP[EEvents.INSIGHT], value: EEvents.INSIGHT },
  { optionLabel: EVENTS_NAME_MAP[EEvents.DECISION], value: EEvents.DECISION },
  { optionLabel: EVENTS_NAME_MAP[EEvents.RESOLUTION], value: EEvents.RESOLUTION },
  { optionLabel: EVENTS_NAME_MAP[EEvents.ACTION], value: EEvents.ACTION },
  { optionLabel: EVENTS_NAME_MAP[EEvents.STOPPED_STALLED], value: EEvents.STOPPED_STALLED },
  { optionLabel: EVENTS_NAME_MAP[EEvents.END], value: EEvents.END },
];

export enum EEventStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  LATE = 'LATE',
}

export const EVENT_STATUS_NAME_MAP = {
  [EEventStatus.NOT_STARTED]: 'Not started',
  [EEventStatus.IN_PROGRESS]: 'In progress',
  [EEventStatus.COMPLETED]: 'Completed',
  [EEventStatus.LATE]: 'Late',
};

export const EVENT_STATUS_COLOR_MAP = {
  [EEventStatus.NOT_STARTED]: colors.gray,
  [EEventStatus.IN_PROGRESS]: colors.orange_dark,
  [EEventStatus.COMPLETED]: colors.green,
  [EEventStatus.LATE]: colors.red,
};

export const EVENTS_STATUS_OPTIONS = [
  { optionLabel: EVENT_STATUS_NAME_MAP[EEventStatus.NOT_STARTED], value: EEventStatus.NOT_STARTED },
  { optionLabel: EVENT_STATUS_NAME_MAP[EEventStatus.IN_PROGRESS], value: EEventStatus.IN_PROGRESS },
  { optionLabel: EVENT_STATUS_NAME_MAP[EEventStatus.COMPLETED], value: EEventStatus.COMPLETED },
  { optionLabel: EVENT_STATUS_NAME_MAP[EEventStatus.LATE], value: EEventStatus.LATE },
];
