import React, { FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import colors from 'theme/colors';

export enum ESize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum EVariant {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  PLAIN = 'plain',
}

type TVariant = 'filled' | 'outlined' | 'plain';
type TSize = 'sm' | 'md' | 'lg';

interface IButtonProps {
  size?: TSize;
  type?: string;
  color?: string;
  textColor?: string;
  variant?: TVariant;
  disabled?: boolean;
  fluid?: boolean;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  cypressAttribute?: string;

  [key: string]: any;
}

const filled = css<IButtonProps>`
  background-color: ${({ color }) => color || colors.orange};
`;
const outlined = css<IButtonProps>`
  border: ${({ color }) => `1px solid ${color || colors.orange}`};
  color: ${({ color, textColor, theme }) => color || textColor || theme.colors.white};
`;
const plain = css<IButtonProps>`
  padding: 0;
  color: ${({ color, textColor, theme }) => color || textColor || theme.colors.white};
`;

const VARIANT_MAP = {
  [EVariant.FILLED]: filled,
  [EVariant.OUTLINED]: outlined,
  [EVariant.PLAIN]: plain,
};

export const PADDING_MAP = {
  [ESize.SM]: '6px 8px',
  [ESize.MD]: '12px 24px',
  [ESize.LG]: '16px 24px',
};

const StyledButton = styled.button<IButtonProps>`
  background-color: transparent;
  font-family: Inter, sans-serif;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 4px;
  cursor: pointer;
  line-height: 16.5px;
  font-size: 14px;
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  color: ${({ textColor }) => textColor || '#ffffff'};
  padding: ${({ size }) => (size ? PADDING_MAP[size] : PADDING_MAP[ESize.MD])};
  ${({ variant }) => (variant ? VARIANT_MAP[variant] : VARIANT_MAP[EVariant.FILLED])};

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const Button: FC<IButtonProps> = ({
  children,
  rightIcon,
  leftIcon,
  disabled,
  type = 'button',
  cypressAttribute,
  ...props
}) => {
  return (
    <StyledButton type={type} disabled={disabled} {...props} data-cy={cypressAttribute}>
      {leftIcon && leftIcon}
      {children}
      {rightIcon && rightIcon}
    </StyledButton>
  );
};

export default Button;
