import styled from 'styled-components';

import checkMark from 'assets/icons/checkmarkChevron.svg';

export const Label = styled.label`
  display: block;
  padding: 12px;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.dark2};
  font-weight: 400;
  cursor: pointer;
`;

export const CustomRadio = styled.span`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-radius: 50%;
  border-color: ${({ theme }) => theme.colors.dark4};
  background-color: ${({ theme }) => theme.colors.dark2};

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    opacity: 0;
    border-radius: 50%;
    transition: opacity 0.2s;
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

export const Input = styled.input`
  width: 0;
  height: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  &:checked + ${CustomRadio} {
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.orange};
    background-color: ${({ theme }) => theme.colors.dark2};
  }
  &:checked + ${CustomRadio}:after {
    opacity: 1;
    color: ${({ theme }) => theme.colors.blue};
  }
`;
