import React from 'react';
import { DropdownMenu, MenuItem } from 'modules/header/components/Layout';
import { ISearchThreadsResult } from 'modules/threads/types';
import SearchItem from 'modules/header/components/SearchItem';

interface ISearchResults {
  searchResults: ISearchThreadsResult[];
  searchValue: string;
}

export const SearchResults: React.FC<ISearchResults> = ({ searchResults, searchValue }) => {
  return (
    <DropdownMenu>
      {searchResults.length ? (
        <>
          {searchResults.map((thread) => (
            <SearchItem key={thread.id} search={searchValue} {...thread} />
          ))}
        </>
      ) : (
        <MenuItem>threads not found</MenuItem>
      )}
    </DropdownMenu>
  );
};

export default SearchResults;
