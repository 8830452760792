import { toUnderscoreCase } from 'utils/mappers';
/* eslint-disable */
// @ts-ignore
const getKey = (parent?: string, key: string, withBrackets: boolean) =>
  // eslint-disable-next-line no-nested-ternary
  parent ? (withBrackets ? `${parent}[${key}]` : `${parent}${key}`) : key;
const arrayMapper = (arr: any[], formData: FormData, parentKey: string, mapper: any) =>
  arr.forEach((item, index) => mapper(item, formData, `${parentKey}[${index}]`, false));

const mapper = (obj: any, formData: FormData, parentKey?: string, withBrackets = true) => {
  Object.keys(obj).forEach((item) => {
    if (Array.isArray(obj[item])) {
      arrayMapper(obj[item], formData, item, mapper);
      return;
    }
    if (typeof obj[item] === 'object' && !(obj[item] instanceof File) && obj[item] !== null) {
      mapper(obj[item], formData, getKey(parentKey, item, withBrackets));
      return;
    }
    if (obj[item] === null) {
      formData.append(getKey(parentKey, toUnderscoreCase(item), withBrackets), '');
      return;
    }
    formData.append(getKey(parentKey, toUnderscoreCase(item), withBrackets), obj[item]);
  });
};

export const toFormData = (obj: any) => {
  const formData = new FormData();
  mapper(obj, formData);
  return formData;
};
