import styled from 'styled-components';
import colors from 'theme/colors';
import { Group, Text } from 'ui';

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const FilePreview = styled(Group)`
  padding: 12px 16px 12px 12px;
  background-color: ${colors.dark2};
  border-radius: 4px;
  max-width: 100%;
`;

export const LabelsContainer = styled(Group)`
  margin-bottom: 16px;
`;

export const FileName = styled(Text)`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InputReplace = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  z-index: 2;
`;
