import styled from 'styled-components';
import { Stack } from 'ui';

export const SubscriptionMainContainer = styled(Stack)`
  padding: 20px;
`;

export const SubscriptionHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 510px;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.dark2};
`;

export const SubscriptionsMainCardsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-basis: 200px;
  justify-content: center;
  gap: 24px;
`;

export const SubscriptionsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 10px 30px 10px;
  max-width: 210px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.orange};
  box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.colors.orange} inset;
`;
