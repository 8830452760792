import { EThreadStatuses } from 'types/index';
import colors from 'theme/colors';
import { EEventStatus } from 'constants/events';

export const STATUS_NAMES = {
  [EThreadStatuses.COMPLETE]: 'Complete',
  [EThreadStatuses.IN_PROGRESS]: 'In progress',
  [EThreadStatuses.TO_DO]: 'To do',
  [EThreadStatuses.STALLED]: 'Stalled',
  [EThreadStatuses.AT_RISK]: 'At risk',
  [EThreadStatuses.AHEAD_OF_SCHEDULE]: 'Ahead of schedule',
  [EThreadStatuses.NEEDS_ATTENTION]: 'Needs attention',
  [EThreadStatuses.ON_TARGET]: 'On target',
  [EThreadStatuses.DELETED]: 'Deleted',
  [EThreadStatuses.ARCHIVE]: 'Archive',
  [EEventStatus.NOT_STARTED]: 'Not started',
  [EEventStatus.IN_PROGRESS]: 'In progress',
  [EEventStatus.COMPLETED]: 'Completed',
  [EEventStatus.LATE]: 'Late',
};

export const STATUS_COLORS = {
  [EThreadStatuses.IN_PROGRESS]: colors.orange_dark,
  [EThreadStatuses.TO_DO]: colors.green1,
  [EThreadStatuses.COMPLETE]: colors.green2,
  [EThreadStatuses.STALLED]: colors.red,
  [EThreadStatuses.AT_RISK]: colors.orange,
  [EThreadStatuses.AHEAD_OF_SCHEDULE]: colors.blue,
  [EThreadStatuses.NEEDS_ATTENTION]: colors.red,
  [EThreadStatuses.ON_TARGET]: colors.green1,
  [EThreadStatuses.DELETED]: colors.blue9,
  [EThreadStatuses.ARCHIVE]: colors.gray,
  [EEventStatus.NOT_STARTED]: colors.gray,
  [EEventStatus.IN_PROGRESS]: colors.orange,
  [EEventStatus.COMPLETED]: colors.green,
  [EEventStatus.LATE]: colors.red,
};
