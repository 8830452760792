import { useAppSelector } from 'app/hooks';
import { selectUserInfo } from 'modules/auth/userSlice';
import { useLazyGetCurrentSiteSubscriptionQuery } from 'modules/integrations/integrationsApi';
import { useEffect } from 'react';

export const useSubscription = () => {
  const { isSiteOwner } = useAppSelector(selectUserInfo);
  const [fetchSubscription, { data, isLoading, isError }] =
    useLazyGetCurrentSiteSubscriptionQuery();
  useEffect(() => {
    if (isSiteOwner) {
      fetchSubscription();
    }
  }, [isSiteOwner]);
  return { data, isLoading, isError };
};
