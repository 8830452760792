import styled from 'styled-components';
import { IGanttTaskList, IGanttTaskHeader, ITooltipProps } from 'modules/threads/types';
import { Button, Stack } from 'ui';

export const GanttTable = styled.div<Partial<IGanttTaskList>>`
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
  display: table;
`;

export const GanttTableHeader = styled.div<Partial<IGanttTaskHeader>>`
  height: ${({ headerHeight }) => `${headerHeight}px` || 'auto'};
  display: table-row;
  list-style: none;
`;

export const GanttHeaderItem = styled.div<Partial<IGanttTaskHeader>>`
  min-width: ${({ rowWidth }) => rowWidth || 'auto'};
  color: ${({ theme }) => theme.colors.gray};
  display: table-cell;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
`;

export const GanttTableRow = styled.div<Partial<IGanttTaskList>>`
  height: ${({ rowHeight }) => `${rowHeight}px` || 'auto'};
  color: ${({ theme }) => theme.colors.gray};
  display: table-row;
  text-overflow: ellipsis;

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.dark2};
  }
`;

export const GanttTableCell = styled.div<Partial<IGanttTaskList>>`
  min-width: ${({ rowWidth }) => rowWidth || 'auto'};
  max-width: ${({ rowWidth }) => rowWidth || 'auto'};
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: ${({ theme }) => theme.colors.gray};
    padding-left: 15px;
    &:hover {
      color: ${({ theme }) => theme.colors.orange};
    }
  }
`;

export const GanttTooltip = styled.div<Partial<ITooltipProps>>`
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
  background-color: ${({ theme }) => theme.colors.blue4};
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px 15px;
  border-radius: 4px;

  span {
    color: ${({ theme }) => theme.colors.orange};
  }
`;

export const GanttSwitchButton = styled(Button)<{ isActive?: boolean }>`
  color: ${({ theme, isActive }) => isActive && theme.primaryColor};
  border-color: ${({ theme, isActive }) => isActive && theme.primaryColor};
  color: ${({ isActive }) => isActive && 'white'};
  background-color: ${({ theme, isActive }) => isActive && theme.primaryColor};

  &:hover {
    background-color: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.primaryColor};
    color: white;
  }
`;

export const Description = styled.div`
  max-width: 450px;
  color: ${({ theme }) => theme.colors.orange};
`;

export const GanttContainer = styled(Stack)`
  .calendar {
    .calendarTop {
      line {
        stroke: none;
      }
    }
    rect {
      fill: ${({ theme }) => theme.colors.dark1};
      stroke: rgba(255, 255, 255, 0.1);
    }
    text {
      fill: ${({ theme }) => theme.colors.gray};
    }
  }
  .gridBody {
    .rows {
      rect {
        fill: ${({ theme }) => theme.colors.dark1};
        &:nth-child(even) {
          fill: ${({ theme }) => theme.colors.dark2};
        }
      }
    }
    .rowLines,
    .ticks {
      line {
        stroke: rgba(255, 255, 255, 0.1);
      }
    }
  }
  .content .bar text {
    fill: white;
  }

  [dir='ltr'],
  [dir='rtl'] {
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.gray};
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.gray1};
    }
  }

  svg {
    max-width: none;
  }
`;
