import React, { FC, useContext } from 'react';
import { Avatar, Stack } from 'ui';
import { useAppSelector } from 'app/hooks';
import { selectUserInfo } from 'modules/auth/userSlice';
import { IMessageProps } from '../../types';
import * as ChatLayout from '../Layout';
import { MessageContext } from './index';
import MessageContent from './MessageContent';

const Message: FC<IMessageProps> = ({
  creator,
  isReplied = false,
  hasReplies = false,
  showReplyBorder = false,
  ...messageProps
}) => {
  const { isOwner: isRootOwner } = useContext(MessageContext);
  const { id: userId } = useAppSelector(selectUserInfo);
  const isOwner = creator?.id === userId;
  const withOutLine =
    !isReplied || (isReplied && ((!isRootOwner && isOwner) || (isRootOwner && !isOwner)));
  return (
    <ChatLayout.Message
      owner={isOwner}
      isRootOwner={isRootOwner}
      showBorder={showReplyBorder}
      data-cy="chat-message-layout"
    >
      <ChatLayout.ReplayLine hasReplies={hasReplies} data-cy="chat-message-replay-line">
        <Stack style={{ position: 'relative', zIndex: 5 }} justify="center">
          {withOutLine ? (
            <Avatar src={creator?.avatar} cypressAttribute="message-creator-avatar" />
          ) : (
            <ChatLayout.WithLine owner={isOwner}>
              <Avatar src={creator?.avatar} cypressAttribute="message-creator-avatar-with-line" />
            </ChatLayout.WithLine>
          )}
        </Stack>
      </ChatLayout.ReplayLine>
      {isReplied && withOutLine ? (
        <ChatLayout.WithLine
          style={{ flex: 1 }}
          owner={!isOwner}
          data-cy="chat-message-layout-with-line"
        >
          <MessageContent
            isOwner={isOwner}
            isReplied={isReplied}
            creator={creator}
            {...messageProps}
          />
        </ChatLayout.WithLine>
      ) : (
        <MessageContent
          isOwner={isOwner}
          isReplied={isReplied}
          creator={creator}
          {...messageProps}
        />
      )}
    </ChatLayout.Message>
  );
};

export default Message;
