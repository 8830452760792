import React from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';

interface ITabLink {
  active?: boolean;
  children: React.ReactNode;
}

const TabNavLink = styled.span<ITabLink>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 8px;
  max-width: 213px;
  line-height: normal;
  color: ${({ active }) => (active ? `${colors.orange}` : 'white')};
  border: ${({ active }) => (active ? `1px solid ${colors.orange}` : 'none')};
  background-color: ${({ active }) => (active ? colors.addBtnBg : 'transparent ')};
  &:hover {
    opacity: 0.9;
  }
`;

export const TabLink: React.FC<ITabLink> = ({ children, active }) => {
  return <TabNavLink active={active}>{children}</TabNavLink>;
};

export default TabLink;
