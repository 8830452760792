import { SSO_LOGIN_URL } from 'constants/sso';
import { NOTIFICATION } from 'modules/alert/constants';
import { showErrorMessage } from 'modules/alert/utils';
import { useSearchParams } from 'react-router-dom';
import useLocalStorage from './useLocalStorage';

interface IUseSSO {
  signUpWithSSO: () => void;
  loginWithSSO: () => void;
  authInviteToken: string | null;
}

const useSSO = () => {
  const [authInviteToken, setAuthInviteToken] = useLocalStorage('auth_invite_token');
  const [query] = useSearchParams();

  const signUpWithSSO = () => {
    const inviteToken = query.get('invite');
    if (!inviteToken) {
      showErrorMessage(NOTIFICATION.INVALID_INVITE_LINK);
      return;
    }
    // set token to local storage because we will need this invite
    // token authenticate user after SSO redirect back to our app
    setAuthInviteToken(inviteToken);
    window.open(SSO_LOGIN_URL, '_self');
  };

  const loginWithSSO = () => {
    window.open(SSO_LOGIN_URL, '_self');
  };

  return { signUpWithSSO, loginWithSSO, authInviteToken };
};

export default useSSO;
