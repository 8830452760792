import { useParams } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { selectThread } from 'modules/threads/threadsSlice';
import { selectUserInfo } from 'modules/auth/userSlice';
import { useCallback } from 'react';

export const useTreadCreator = () => {
  const { id: threadId } = useParams();
  const { creator } = useAppSelector(selectThread(threadId as string));
  const { id: currentUserId } = useAppSelector(selectUserInfo);

  const isUserThreadCreator = useCallback(
    (userId?: string) => {
      if (!creator || !threadId) return false;
      if (userId) return creator.id === userId;
      return creator.id === currentUserId;
    },
    [threadId]
  );

  return { isUserThreadCreator };
};
