import React from 'react';
import styled from 'styled-components';
import AccessControl from 'app/permissions/AccessControl';
import { useParams } from 'react-router-dom';
import { Avatar, Group, Stack, Text, Button, Icon } from 'ui';
import { useModal } from 'modules/modals/ModalProvider';
import { IContributor } from 'modules/contributors/types';
import { EPermissions } from 'app/permissions/constants';
import { useTreadCreator } from 'hooks/useThreadCreator';
import * as Layout from './Layout';

const ContributorCard: React.FC<IContributor> = ({ id, user }) => {
  const { id: threadId } = useParams();
  const { open } = useModal();
  const { isUserThreadCreator } = useTreadCreator();
  const { firstName, lastName, email, avatar, id: userId } = user;
  const removeProps = { id, firstName, lastName, email, avatar };
  const userName = !!firstName || !!lastName;

  const handleRemove = () => {
    open({
      variant: 'center',
      contentLabel: 'remove contributor',
      name: 'Remove Contributor',
      modal: 'removeContributor',
      context: {
        ...removeProps,
      },
      id: 'modal-remove-contributor',
    });
  };

  return (
    <Layout.Card align="center" justify="space-between" data-cy="contributor-card">
      <Group gap="12px">
        <Avatar src={avatar} cypressAttribute="contributor-card-avatar" />
        <Stack align="center" justify="center">
          {userName ? (
            <Text weight="500" cypressAttribute="contributor-card-name">
              {firstName} {lastName}
            </Text>
          ) : (
            <Text cypressAttribute="contributor-card-email">{email}</Text>
          )}
        </Stack>
      </Group>
      {!isUserThreadCreator(userId) && (
        <AccessControl
          permissions={[EPermissions.DELETE_CONTRIBUTORS]}
          threadId={threadId as string}
        >
          <Layout.RemoveButton
            variant="plain"
            onClick={handleRemove}
            cypressAttribute="contributor-card-remove-user-btn"
          >
            <Icon icon="RemoveUserIcon" />
          </Layout.RemoveButton>
        </AccessControl>
      )}
    </Layout.Card>
  );
};

export default ContributorCard;
