import styled from 'styled-components';
import colors from 'theme/colors';
import { Button, Group, Stack } from 'ui';

export const Section = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 240px 1fr;
  gap: 70px;
`;

export const UsersList = styled(Stack)`
  gap: 12px;
`;

export const CardUserContainer = styled(Group)`
  position: relative;
  display: flex;
  gap: 12px;
  padding: 16px;
  margin-right: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.dark2};
`;

export const CardUser = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 16px;
  margin-right: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.dark1};
`;

export const ListControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const UserNamesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

export const UserRoleSelect = styled.div`
  width: 100%;
  min-width: 170px;
  max-width: 200px;
`;

export const RemoveButton = styled(Button)`
  font-size: 22px;
`;

export const RoleContainer = styled.div`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.dark2};
  padding: 13px;
  width: 100%;
  min-width: 170px;
`;

// New styles

export const MainContainer = styled(Stack)`
  background-color: ${({ theme }) => theme.colors.dark2};
  border-radius: 8px;
  padding: 20px;
  gap: 16px;
`;
export const MemberListsContainer = styled(Group)`
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 16px;
`;
export const InvitedMembersWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  max-height: 400px;
`;

export const InvitedMembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
`;
export const WorkspaceMembersWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  flex-grow: 3;
  height: 100%;
  max-height: 400px;
`;
export const WorkspaceMembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
`;

export const IntegrationListContainer = styled.ul`
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
`;

export const AddNewIntegrationButton = styled(Button)`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: ${colors.dark2};
  height: 154px;
  border-radius: 8px;
  padding: 20px 10px;
  transition: background-color 0.2s ease-out;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  &:active {
    opacity: 0.6;
  }
`;

export const SiteSubscriptionLabel = styled.div`
  padding: 8px 20px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  background-color: ${colors.orange};
`;

export const UpgradeButton = styled(Button)`
  padding: 8px 20px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  background-color: ${colors.dark1};
`;
