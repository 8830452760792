import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { resetAll } from 'app/api';
import { EThreadPriority, EThreadStatuses } from 'types';
import { IThreadsResult, TNumericallyKeyedThreadsResult } from './types';
import { threadsListApi } from './threadsApi';

interface IThreadsState {
  [key: string]: IThreadsResult;
}

export const initialState: IThreadsState = {};
export const defaultThread = <IThreadsResult>(<unknown>{
  creator: {},
  name: '',
  references: [],
  tags: [],
  status: EThreadStatuses.COMPLETE,
  priority: EThreadPriority.LOW,
});

const threadsSlice = createSlice({
  name: 'threads',
  initialState,
  reducers: {
    updateThread: (state, { payload }: PayloadAction<IThreadsResult>) => {
      state[payload.id] = payload;
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(resetAll, () => initialState)
      .addMatcher(threadsListApi.endpoints.getThreadsList.matchFulfilled, (state, { payload }) => {
        const threadById: TNumericallyKeyedThreadsResult = {};
        payload.results.forEach((result: IThreadsResult) => {
          threadById[result.id] = result;
        });
        return threadById;
      })
      .addMatcher(threadsListApi.endpoints.getThread.matchFulfilled, (state, { payload }) => ({
        ...state,
        [payload.id]: payload,
      })),
});

export const { updateThread } = threadsSlice.actions;
export const selectAllThreads = (state: RootState) => state.threads;
export const selectThread = (id: string) => (state: RootState) =>
  state.threads[id] || defaultThread;
export const selectOwner = (id: string) => (state: RootState) => state.threads[id]?.creator || {};
export const isThreadOwner = (id: string, userId: string) => (state: RootState) =>
  state.threads[id]?.creator?.id === userId;
export default threadsSlice.reducer;
