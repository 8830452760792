import { StylesConfig } from 'react-select';
import colors from 'theme/colors';

export const integrationSelectStyles: StylesConfig = {
  menuPortal: (base) => ({ ...base, zIndex: 9999, width: '100%', maxWidth: 210 }),
  container: (base, state) => ({
    ...base,
    width: '100%',
    maxWidth: 210,
  }),
  menu: (base) => ({
    ...base,
    background: colors.blue2,
    color: '#fff',
    borderRadius: 4,
    padding: 8,
    boxShadow: '0px 4px 4px 5px rgba(0, 0, 0, 0.25)',
  }),
  placeholder: (base, state) => ({
    ...base,
    color: state.isDisabled ? 'rgba(115, 125, 155, 1)' : '#fff',
    fontSize: 14,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled ? 'rgba(115, 125, 155, 1)' : '#fff',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'translateY(-1px)',
    transition: 'all .2s ease',
    '&:hover': {
      color: '#fff',
    },
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.isDisabled ? 'rgba(115, 125, 155, 1)' : '#fff',
    fontSize: 14,
  }),
  control: (base, state) => ({
    ...base,
    background: state.isDisabled ? colors.dark2 : colors.blue2,
    border: state.isDisabled ? '1px solid rgba(115, 125, 155, 1)' : 'none',
    cursor: 'pointer',
    '&:hover': {
      border: 'none',
    },
  }),
  option: (base, state) => ({
    ...base,
    background: state.isFocused ? '#7082F7' : colors.blue2,
    cursor: 'pointer',
    padding: '4px 8px',
    borderRadius: 2,
    fontSize: 12,
    wordBreak: 'break-word',
  }),
};
