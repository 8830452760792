import React, { FC, useMemo } from 'react';
import { useAppSelector } from 'app/hooks';

import { Container } from 'layout/App';
import { Group, Icon, Title, Spinner, Text, Button } from 'ui';

import { useSubscription } from 'hooks/useSubscription';
import { useWindowSize } from 'hooks/useWindowSize';

import { useLazyGetUpgradePlanUrlQuery } from 'modules/user/userApi';
import { useLazyGetSubscriptionRenewalLinkQuery } from 'modules/subscriptionsPlans/subscriptionsPlansApi';

import { selectSubscriptionInfo } from 'modules/subscriptionsPlans/subscriptionsPlansSlice';
import { selectUserInfo } from 'modules/auth/userSlice';

import { mediaSize } from 'theme/devices';
import colors from 'theme/colors';

import { showErrorMessage } from 'modules/alert/utils';

import * as Layout from 'modules/header/components/Layout';

export const Banner: FC = () => {
  const size = useWindowSize();
  const { isLoading: isSubscriptionLoading } = useSubscription();

  const { isActive, subscriptionRenewalInfo } = useAppSelector(selectSubscriptionInfo);
  const { isTrialExpired, isSiteOwner } = useAppSelector(selectUserInfo);

  const [getTrialUpgradePlanUrl, { isLoading }] = useLazyGetUpgradePlanUrlQuery();
  const [getSubscriptionRenewalLink, { isLoading: isSubscriptionLinkLoading }] =
    useLazyGetSubscriptionRenewalLinkQuery();

  const isTrialWorkspaceExpired = useMemo(
    () => isTrialExpired && isSiteOwner,
    [isTrialExpired, isSiteOwner]
  );
  const isPaidWorkspaceExpired = useMemo(
    () => !isActive && isSiteOwner && !isSubscriptionLoading && subscriptionRenewalInfo,
    [isActive, isSiteOwner, isSubscriptionLoading]
  );

  const buttonTextSize = size.width <= mediaSize.laptop ? 'xs' : 'sm';
  const titleTextSize = size.width <= mediaSize.laptop ? '14px' : '18px';

  const handleTrialRenewalClick = () => {
    getTrialUpgradePlanUrl()
      .then((res) => {
        if (res.data?.url) {
          window.open(res.data.url, '_self');
        }
      })
      .catch(() => {
        showErrorMessage('Error while getting trial upgrade plan url');
      });
  };

  const handlePaidRenewalClick = () => {
    getSubscriptionRenewalLink()
      .then((res) => {
        if (res.data?.redirectUrl) {
          window.open(res.data.redirectUrl, '_self');
        }
      })
      .catch(() => {
        showErrorMessage('Error while getting subscription renewal link');
      });
  };

  if (isTrialWorkspaceExpired) {
    return (
      <Layout.TrialBannerContainer>
        <Container>
          <Group align="center" justify="space-between" gap="20px">
            <Group align="center" gap="20px">
              <Icon icon="AlertIcon" size="large" style={{ width: 30, height: 30 }} />
              <Title
                style={{ letterSpacing: '0.7px', fontSize: titleTextSize }}
                heading="h6"
                weight="600"
                cypressAttribute="upgrade-now-title"
              >
                Your trial period has expired! Click &quot;Upgrade now&quot; button for more
                information about upgrading to paid plan.
              </Title>
            </Group>
            <Button
              type="filled"
              color="#000"
              onClick={handleTrialRenewalClick}
              disabled={isLoading}
              cypressAttribute="upgrade-now-btn"
            >
              {isLoading ? (
                <Spinner size="small" color={colors.white} />
              ) : (
                <Text weight="600" align="center" size={buttonTextSize}>
                  Upgrade now
                </Text>
              )}
            </Button>
          </Group>
        </Container>
      </Layout.TrialBannerContainer>
    );
  }

  if (isPaidWorkspaceExpired) {
    return (
      <Layout.TrialBannerContainer>
        <Container>
          <Group align="center" justify="space-between" gap="20px">
            <Group align="center" gap="20px">
              <Icon icon="AlertIcon" size="large" style={{ width: 30, height: 30 }} />
              <Title
                style={{ letterSpacing: '0.7px', fontSize: titleTextSize }}
                heading="h6"
                weight="600"
                cypressAttribute="upgrade-now-title"
              >
                {subscriptionRenewalInfo}
              </Title>
            </Group>
            <Button
              type="filled"
              color="#000"
              onClick={handlePaidRenewalClick}
              disabled={isSubscriptionLinkLoading}
              cypressAttribute="upgrade-now-btn"
            >
              {isSubscriptionLinkLoading ? (
                <Spinner size="small" color={colors.white} />
              ) : (
                <Text weight="600" align="center" size={buttonTextSize}>
                  Continue
                </Text>
              )}
            </Button>
          </Group>
        </Container>
      </Layout.TrialBannerContainer>
    );
  }
  return null;
};
