import React, { FC } from 'react';
import { Button, Group, Icon, Title } from 'ui';
import colors from 'theme/colors';
import { ViewWrapper } from './Layout';
import NotificationList from './NotificationList';
import { useMarkAllAsReadMutation } from '../notificationApi';

interface IProps {
  closeNotificationModal: () => void;
}

const View: FC<IProps> = ({ closeNotificationModal }) => {
  const [markAsRead] = useMarkAllAsReadMutation();

  return (
    <ViewWrapper gap="20px">
      <Group fluid justify="space-between" align="center">
        <Title heading="h6" cypressAttribute="notifications-title">
          Notifications
        </Title>
        <Button
          textColor={colors.gray2}
          onClick={markAsRead}
          variant="plain"
          leftIcon={<Icon stroke={colors.gray2} icon="DoubleCheckIcon" />}
          cypressAttribute="notifications-mark-all-read-btn"
        >
          Mark all as read
        </Button>
      </Group>
      <NotificationList closeNotificationModal={closeNotificationModal} />
    </ViewWrapper>
  );
};

export default View;
