import styled, { css } from 'styled-components';
import { MAIN_BOTTOM_PADDING } from '../constants';

interface IIndicator {
  color?: string;
  isActive: boolean;
  centered?: boolean;
}

const absoluteCenter = css`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ScreenHeight = styled.div`
  height: 100vh;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 32px;
`;

export const Main = styled.main`
  padding: 24px 0 ${MAIN_BOTTOM_PADDING}px;
  background-color: ${({ theme }) => theme.colors.neutralBlue100};
`;

export const StyledLabel = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray2};
  span {
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const Indicator = styled.div<IIndicator>`
  position: relative;
  &:after {
    content: '';
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    position: absolute;
    height: 10px;
    width: 10px;
    bottom: 0;
    right: 0;
    background-color: ${({ color, theme }) => color || theme.colors.indicator};
    border-radius: 50%;
    ${({ centered }) => centered && absoluteCenter}
  }
`;
