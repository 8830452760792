/* eslint-disable */
const matched = (x: any) => ({
  on: () => matched(x),
  otherwise: () => x,
});

const otherwise = (x: any) => (fn: any) => fn(x);

export const switchCase = (x: any) => ({
  on: (value: any, fn: any) => (value === x ? matched(fn(value)) : switchCase(x)),
  otherwise: otherwise(x),
});
