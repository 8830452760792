import React from 'react';
import StatusBadgeWithCircleIcon from 'modules/threads/components/badge/StatusBadgeWithCircleIcon';
import { EventBadge, PriorityBadge, Text } from 'ui';
import { EVariant, ITitle } from './types';

const DropdownTitle: React.FC<ITitle> = ({ badge, value, placeholder }) => {
  if (!value) return <Text>{placeholder}</Text>;

  if (badge === EVariant.PRIORITY) {
    return <PriorityBadge priority={value} />;
  }
  if (badge === EVariant.EVENT) {
    return <EventBadge event={value} />;
  }

  return <StatusBadgeWithCircleIcon status={value} />;
};

export default DropdownTitle;
