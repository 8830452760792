import React from 'react';
import LoginLayout from 'layout/Login';
import { Title } from 'ui';
import colors from 'theme/colors';
import Form from './Form';

const ForgotPassword = () => {
  return (
    <LoginLayout>
      <>
        <div>
          <Title
            heading="h1"
            style={{ marginBottom: '30px' }}
            cypressAttribute="forgot-password-title"
          >
            Forgot Password?
          </Title>
          <Title style={{ fontSize: '16px' }} heading="h5" color={colors.gray1}>
            Enter your email that you used to register your account, so we can send you a link to
            reset your password
          </Title>
        </div>
        <Form />
      </>
    </LoginLayout>
  );
};

export default ForgotPassword;
