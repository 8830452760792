import React, { ReactElement, ReactNode } from 'react';
import AvatarPlaceHolder from 'assets/icons/ava_placeholder.jpg';
// eslint-disable-next-line import/no-unresolved
import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry';
import { HiOutlineUserGroup } from 'react-icons/hi';
import colors from 'theme/colors';

const CustomMentionContainer = (props: EntryComponentProps): ReactElement => {
  const { mention, theme, ...parentProps } = props;

  const customAvatarMap: { [key: string]: ReactNode } = {
    contributors: <HiOutlineUserGroup color={colors.white} size={23} />,
  };

  const avatar = mention.avatar || AvatarPlaceHolder;

  return (
    <div {...parentProps}>
      <div className={theme?.mentionSuggestionsEntryContainer}>
        <div className={theme?.mentionSuggestionsEntryContainerLeft}>
          {customAvatarMap[mention.name] ? (
            customAvatarMap[mention.name]
          ) : (
            <img
              src={avatar}
              className={theme?.mentionSuggestionsEntryAvatar}
              role="presentation"
              alt={mention.name}
            />
          )}
        </div>

        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div className={theme?.mentionSuggestionsEntryText}>{mention.name}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomMentionContainer;
