import React, { FC } from 'react';
import styled from 'styled-components';

import { IBadgeProps } from 'modules/threads/types';

interface IBadge {
  color?: string;
}

const StyledStatusBadge = styled.span<IBadge>`
  padding-left: 16px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ color }) => color || '#ccc'};
  }
`;

const StatusBadge: FC<IBadgeProps> = ({ label, color }) => {
  return <StyledStatusBadge color={color}>{label}</StyledStatusBadge>;
};

export default StatusBadge;
