import styled from 'styled-components';
import colors from 'theme/colors';
import { ICheckProps, ESize } from './types';

const SIZE_MAP = {
  [ESize.SM]: '10px',
  [ESize.MD]: '15px',
  [ESize.LG]: '20px',
  [ESize.XL]: '24px',
};

export const Check = styled.div<ICheckProps>`
  width: ${({ size }) => (size ? SIZE_MAP[size] : SIZE_MAP[ESize.SM])};
  height: ${({ size }) => (size ? SIZE_MAP[size] : SIZE_MAP[ESize.SM])};
  border: 1px solid ${colors.gray3};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    visibility: hidden;
  }
`;

export const Label = styled.label`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
`;

export const Input = styled.input`
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1;

  &:checked ~ {
    ${Check} {
      border: 1px solid ${colors.blue};
      > div {
        visibility: visible;
      }
    }
  }
`;
