/**
 * Local storage helpers
 */

import { RootState } from 'app/store';
import _get from 'lodash/get';
import { SAVED_STATE } from '../constants/index';

/**
 * Save chosen Redux state variables to local storage to persist after refresh.
 */
export const saveReduxStateToLocalStorage = (state: RootState) => {
  // State to be written to local storage
  const {
    app: { debugMode },
  } = state;

  const serializedState = JSON.stringify({
    app: {
      debugMode,
    },
  });

  localStorage.setItem(SAVED_STATE, serializedState);
};

/**
 * Convert stringified JSON stored in localStorage back to a regular JavaScript object
 *
 * @param keyPath `path.to.key` in state object
 */
export const loadStateFromLocalStorage = (stringifiedState: string | null) => {
  if (!stringifiedState) {
    return null;
  }

  try {
    return JSON.parse(stringifiedState);
  } catch {
    return null;
  }
};
