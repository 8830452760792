import { DOMAIN_REGEX } from 'utils/validation';
import { ICopilotSuggestion } from 'modules/copilot/types';
import { ENotificationTypes, INotificationResponse } from './types';
import { NOTIFICATION_TITLE_MAP } from './constants';
import { ENV } from '../../constants/env';

interface IParseTemplateOptions {
  fullName: string;
  threadName: string;
  eventName: string;
  fileName: string;
  fileVersion: number;
}

const replaceTemplate =
  (options: Partial<IParseTemplateOptions>, _?: number, __?: string[]) =>
  (acc: string, option: string) => {
    if (!acc) return 'Notification data not fond.';
    return acc.replace(`{${option}}`, `${options[option as keyof IParseTemplateOptions]}`);
  };

export const parseTemplate = (
  type: ENotificationTypes,
  options: Partial<IParseTemplateOptions>
) => {
  const template = NOTIFICATION_TITLE_MAP[type];
  return Object.keys(options).reduce(replaceTemplate(options), template);
};

export const markAllAsRead = (message: INotificationResponse) => ({ ...message, isRead: true });

export const getAvatarUrl = (url: string | null | undefined) => {
  if (!url) return '';
  const API_URL = ENV.REACT_APP_API_URL_IMAGES || '';
  return DOMAIN_REGEX.test(url) ? url : API_URL + url;
};
