import React, { useRef, useMemo, useState, useCallback } from 'react';
import colors from 'theme/colors';
import { useOnClickOutside, useThreadFilter, useDebounce } from 'hooks';
import { Stack, Group, Button, CalendarInput, Title, Divider, Dropdown, ReactSelect } from 'ui';
import { useGetThreadTagsQuery, useGetSearchThreadsQuery } from 'modules/threads/threadsApi';
import { useGetUserGroupsQuery } from 'modules/user/userApi';
import { createOption } from 'utils/helpers';
import { THREAD_FILTER_STATUSES } from 'constants/index';
import { SEARCH_THREADS_PARAMS } from 'modules/threads/constants';
import { TReactSelectOption } from 'types';
import * as Layout from './Layout';

interface IProps {
  toggleFilter: () => void;
}

const ThreadsFilter: React.FC<IProps> = ({ toggleFilter }) => {
  const {
    searchThreadName,
    searchValueOption,
    statusValue,
    groupsValue,
    tagsValue,
    dateRange,
    handleThreadOptionChange,
    handleThreadInputChange,
    handleChangeStatus,
    handleTagsChange,
    handleGroupChange,
    handleDateChange,
    handleFilterApply,
    resetAll,
  } = useThreadFilter();

  const [searchValue, setSearchValue] = useState(searchThreadName);
  const debouncedSearch = useDebounce(searchValue, 250);

  const { data: threadTags } = useGetThreadTagsQuery('');
  const { data: availableGroups } = useGetUserGroupsQuery();
  const { data: searchThreads } = useGetSearchThreadsQuery({
    limit: SEARCH_THREADS_PARAMS.LIMIT,
    offset: SEARCH_THREADS_PARAMS.OFFSET,
    search: debouncedSearch,
    fields: SEARCH_THREADS_PARAMS.FIELDS,
  });

  const filterWrapper = useRef(null);
  const searchInputRef = useRef(null);
  const tagsInputRef = useRef(null);
  const groupsInputRef = useRef(null);

  useOnClickOutside(filterWrapper, () => toggleFilter());

  const clearFilter = () => {
    // @ts-ignore
    searchInputRef?.current?.clearValue();
    // @ts-ignore
    tagsInputRef?.current?.clearValue();
    // @ts-ignore
    groupsInputRef?.current?.clearValue();
    resetAll();
  };

  const threadsOptions: TReactSelectOption[] = useMemo(
    () => searchThreads?.results?.map((thread) => createOption(thread.name)) || [],
    [searchThreads, debouncedSearch]
  );
  const tagOptions: TReactSelectOption[] = useMemo(
    () => threadTags?.map((tag) => createOption(tag)) || [],
    [threadTags]
  );
  const groupOptions = useMemo(
    () => availableGroups?.groups.map((group) => createOption(group)) || [],
    [availableGroups]
  );

  const handleMenuClose = useCallback(() => {
    setSearchValue('');
  }, []);

  return (
    <Layout.FilterWrapper ref={filterWrapper}>
      <Layout.Filter>
        <Stack gap="24px">
          <Title heading="h6" weight="600" color={colors.gray2}>
            Filter
          </Title>
          <Divider color={colors.blue3} />
          <ReactSelect
            ref={searchInputRef}
            id="threads-name"
            label="Thread"
            isMulti={false}
            isCreatable={false}
            isSearchable
            defaultValue={searchValueOption}
            options={threadsOptions}
            placeholder="Thread Name"
            onChange={handleThreadOptionChange}
            onInputChange={handleThreadInputChange}
            onMenuOpen={handleMenuClose}
          />
          <Dropdown
            label="Status"
            name="status"
            badge="status"
            placeholder="Choose status"
            bg={colors.dark2}
            defaultValue={statusValue}
            options={THREAD_FILTER_STATUSES}
            onClick={handleChangeStatus}
          />
          <ReactSelect
            ref={groupsInputRef}
            label="Current User Group"
            id="user-group-select"
            isCreatable={false}
            isSearchable
            defaultValue={groupsValue}
            options={groupOptions}
            placeholder="Select groups"
            onChange={handleGroupChange}
          />
          <ReactSelect
            ref={tagsInputRef}
            id="tags-select"
            label="Tags/Keywords"
            isCreatable={false}
            isSearchable
            defaultValue={tagsValue}
            options={tagOptions}
            placeholder="Select tags"
            onChange={handleTagsChange}
          />
          <CalendarInput
            label="Last Modified Date"
            selectRange
            range={dateRange}
            onRangeChange={handleDateChange}
          />
          <Group justify="end" gap="15px">
            <Button color={colors.dark2} onClick={clearFilter}>
              Clear All
            </Button>
            <Button onClick={handleFilterApply}>Apply</Button>
          </Group>
        </Stack>
      </Layout.Filter>
    </Layout.FilterWrapper>
  );
};

export default ThreadsFilter;
