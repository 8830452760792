import React, { createContext, useMemo, useRef, MutableRefObject, useContext } from 'react';
import TextEditor from '@draft-js-plugins/editor';
import { VirtuosoHandle } from 'react-virtuoso';

interface IContextProps {
  focusedInputRef: MutableRefObject<TextEditor | undefined>;
  chatRef: MutableRefObject<VirtuosoHandle | undefined>;
  focus: (ref: TextEditor | undefined, scrollDestination: number | undefined) => void;
}

export const FocusContext = createContext<IContextProps | undefined>(undefined);

/**
 * @description Manages the currently focused text input in the thread
 *
 * When a user clicks 'Reply' on a message, the `focus` method should be invoked with
 * the newly created input's ref
 */
export const FocusProvider = ({ children }: { children: React.ReactNode }) => {
  // Currently focused editor
  const focusedInputRef = useRef<TextEditor>();

  // Chat (thread) container
  const chatRef = useRef<VirtuosoHandle>();

  /**
   * @description Moves focus to the provided text editor. If a destination is provided, scroll to it.
   * @param ref: The target ref to focus
   * @param scrollDestination: The index of the target within the chat (Virtuoso component). Optional
   */
  const focus = (ref: TextEditor | undefined, scrollDestination: number | undefined) => {
    focusedInputRef.current = ref;
    if (scrollDestination !== undefined && chatRef.current) {
      chatRef.current?.scrollToIndex({
        index: scrollDestination,
        behavior: 'auto',
        align: 'start',
      });
    }

    // Focusing on mount will disrupt DraftJS plugins like mentions
    // From https://github.com/draft-js-plugins/draft-js-plugins/issues/800
    //
    // > The underlying issue is something like this (from memory):
    // > 1. A new editor component is created
    // > 2. The plugins component takes the initial state and adds all decorators from active plugins and
    // >    then calls the passed in state updated function from the props (but doesn't update the internal
    // >    state, as it is expecting the component parent to do that)
    // > 3. focus is called in the same render cycle and reads from props.state, changes the focus and
    // >    then calls update state with that new state (which doesn't include the plugin decorators as
    // >    the cycle from 2 didn't finish updating)
    // > 4. 3 overwrites the new state from 2 kicking out all decorators, breaking mentions, emojis, etc.
    //
    // So I believe `setTimeout` bumps the focus call to the next render cycle, so plugins aren't removed
    setTimeout(() => focusedInputRef.current?.focus(), 0);
  };

  const data = useMemo(
    () => ({
      focusedInputRef,
      chatRef,
      focus,
    }),
    [focusedInputRef, chatRef]
  );

  return <FocusContext.Provider value={data}>{children}</FocusContext.Provider>;
};

export const useFocus = () => {
  const context = useContext(FocusContext);
  if (!context) {
    throw new Error('Attempted to access FocusContext with missing Provider');
  }
  return context;
};
