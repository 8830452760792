export enum EVariant {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum EPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left',
}

export enum EWidth {
  AUTO = 'auto',
  FULL = 'full',
  MD = 'md',
  LG = 'lg',
}

export enum EAlignment {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}

type TVariant = 'light' | 'dark';
type TPosition = 'top' | 'bottom' | 'right' | 'left';
type TWidth = 'auto' | 'md' | 'lg' | 'full';
type TAlignment = 'start' | 'center' | 'end';

export type TextProps = {
  mode?: TVariant;
  position?: TPosition;
  width?: TWidth;
  alignment?: TAlignment;
};

export interface ITooltip {
  data: string | React.ReactElement | Array<string>;
  children: React.ReactElement | string;
  position?: TPosition;
  mode?: TVariant;
  width?: TWidth;
  alignment?: TAlignment;
  isVisible?: boolean;
  cypressAttribute?: string;
}
