import { useEffect, useState } from 'react';

/**
 * Check user has clicked anywhere on the page (interacted) since initial load.
 */
const usePageInteraction = (isLoading = false) => {
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const handleInteraction = () => {
      setHasInteracted(true);
    };

    // If page is loading, we don't want to listen for clicks.
    if (!isLoading) {
      document.addEventListener('click', handleInteraction);
    }

    return () => {
      document.removeEventListener('click', handleInteraction);
    };
  }, []);

  return hasInteracted;
};

export default usePageInteraction;
