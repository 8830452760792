import React, { forwardRef } from 'react';
import AccessControl from 'app/permissions/AccessControl';
import { useParams } from 'react-router-dom';
import { Icon, Text, Menu } from 'ui';
import { IAction, IProps } from './types';
import * as Layout from './Layout';

const Action = forwardRef<HTMLDivElement, IAction>(({ title, action, icon, permissions }, ref) => {
  const { id } = useParams();
  return (
    <AccessControl permissions={permissions || []} threadId={id as string}>
      <Layout.Action ref={ref} gap="8px" key={title} onClick={action}>
        {icon}
        <Text>{title}</Text>
      </Layout.Action>
    </AccessControl>
  );
});

const DropDownAction: React.FC<IProps> = ({
  actions,
  position = 'horizontal',
  dropDownPosition = 'right',
  children,
  onClose,
  cypressAttribute,
}) => {
  const iconPosition = position === 'vertical' ? '90deg' : undefined;

  return (
    <Menu
      data={actions}
      Item={Action}
      dropDownPosition={dropDownPosition}
      onClose={onClose}
      cypressAttribute={cypressAttribute}
    >
      {children || <Icon rotate={iconPosition} icon="DotsIcon" />}
    </Menu>
  );
};

export default DropDownAction;
