import React, { useState, ChangeEvent, useRef } from 'react';
import colors from 'theme/colors';
import { Button, Group } from 'ui';
import * as Layout from './Layout';

interface ISwitchProps {
  leftText?: string;
  rightText?: string;
  checked: boolean;
  onChange: (val: boolean) => void;
  cypressAttribute?: string;
}

const Switch: React.FC<ISwitchProps> = ({
  leftText,
  rightText,
  checked,
  onChange,
  cypressAttribute,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const inputRef = useRef<HTMLLabelElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.checked;
    setIsChecked(val);
    onChange?.(val);
  };

  const toggleSlider = () => {
    inputRef?.current?.click();
  };

  return (
    <Group align="center" gap="8px">
      {leftText && (
        <Button
          color={isChecked ? colors.dark4 : 'white'}
          variant="plain"
          onClick={toggleSlider}
          cypressAttribute={cypressAttribute && `${cypressAttribute}-left-text`}
        >
          {leftText}
        </Button>
      )}
      <Layout.Label ref={inputRef} data-cy={cypressAttribute && `${cypressAttribute}-control`}>
        <Layout.Input type="checkbox" checked={isChecked} onChange={handleChange} />
        <Layout.Slider />
      </Layout.Label>
      {rightText && (
        <Button
          color={isChecked ? 'white' : colors.dark4}
          variant="plain"
          onClick={toggleSlider}
          cypressAttribute={cypressAttribute && `${cypressAttribute}-right-text`}
        >
          {rightText}
        </Button>
      )}
    </Group>
  );
};

export default Switch;
