import React, { useState, useMemo, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { createOption } from 'utils/helpers';
import { THREAD_OPTIONS_STATUSES } from 'constants/index';
import { updateFilter, selectFilter, resetFilter } from 'modules/threads/filterSlice';
import { EThreadStatuses, TReactSelectOption } from 'types';

const useFilter = () => {
  const filter = useAppSelector(selectFilter);
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState(filter.search);
  const [status, setStatus] = useState(filter.status);
  const [tags, setTags] = useState(filter.tags);
  const [groups, setGroups] = useState(filter.group);
  const [dateRange, setDateRange] = useState(filter.date);

  const handleThreadOptionChange = useCallback(
    (option: TReactSelectOption) => {
      const value = option?.value ? option.value : '';
      setSearch(value);
    },
    [search]
  );
  const handleThreadInputChange = useCallback(
    (value: string) => {
      if (value === '') return;
      setSearch(value);
    },
    [search]
  );
  const handleChangeStatus = useCallback(
    (value: string) => {
      setStatus(value as EThreadStatuses);
    },
    [status]
  );
  const handleTagsChange = useCallback(
    (options: TReactSelectOption[]) => {
      const newTags = options.map((option: TReactSelectOption) => option.value);
      setTags(newTags);
    },
    [tags]
  );
  const handleGroupChange = useCallback(
    (options: TReactSelectOption[]) => {
      const newGroups = options.map((option: TReactSelectOption) => option.value);
      setGroups(newGroups);
    },
    [groups]
  );
  const handleDateChange = useCallback(
    (value: Date[]) => {
      setDateRange(value);
    },
    [dateRange]
  );

  const searchValueOption = useMemo(() => (search ? createOption(search) : []), [search]);
  const tagsValue = useMemo(() => tags.map((tag) => createOption(tag) || []), [tags]);
  const groupsValue = useMemo(() => groups.map((group) => createOption(group) || []), [groups]);
  const statusValue = useMemo(
    () => (status ? THREAD_OPTIONS_STATUSES.find((item) => item.value === status) : null),
    [status]
  );

  const handleFilterApply = () => {
    const params = {
      search,
      tags,
      group: groups,
      status,
      date: dateRange,
    };
    dispatch(updateFilter(params));
  };

  const resetAll = () => {
    setDateRange([]);
    setStatus('');
    dispatch(resetFilter());
  };

  return {
    searchThreadName: search,
    searchValueOption,
    tagsValue,
    groupsValue,
    statusValue,
    dateRange,
    handleThreadOptionChange,
    handleThreadInputChange,
    handleChangeStatus,
    handleTagsChange,
    handleGroupChange,
    handleDateChange,
    handleFilterApply,
    resetAll,
  };
};

export default useFilter;
