import React, { FC } from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { Group, Stack } from 'ui';

const CardSkeletonAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.blue5};
  animation: skeletonShine 1s ease-in-out forwards infinite;
  animation-direction: alternate;
`;

const CardSkeleton = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.dark1};
  animation: skeletonShine 0.9s ease-in-out forwards infinite;
  animation-direction: alternate;
`;

const CardSkeletonInner = styled.div`
  z-index: 1;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.blue5};
`;

export const MemberCardSkeleton: FC = () => {
  return (
    <CardSkeleton>
      <Group gap="12px" align="center">
        <CardSkeletonAvatar />
        <CardSkeletonInner style={{ width: '250px', height: '18px' }} />
      </Group>
      <Group gap="16px" align="center">
        <CardSkeletonInner style={{ width: '170px', height: '42px' }} />
        <CardSkeletonInner style={{ width: '24px', height: '24px', borderRadius: '50%' }} />
      </Group>
    </CardSkeleton>
  );
};
