import colors from 'theme/colors';
import { EThreadPriority } from 'types';

export const PRIORITY_NAMES = {
  [EThreadPriority.LOW]: 'Low',
  [EThreadPriority.MEDIUM]: 'Medium',
  [EThreadPriority.HIGH]: 'High',
};

export const PRIORITY_COLORS = {
  [EThreadPriority.LOW]: {
    text: colors.brandSecondaryLight,
    bg: colors.blue4,
  },
  [EThreadPriority.MEDIUM]: {
    text: colors.orange,
    bg: colors.orange1,
  },
  [EThreadPriority.HIGH]: {
    text: colors.red2,
    bg: colors.red3,
  },
};
