import React from 'react';
import styled from 'styled-components';
import { Spinner, Stack } from 'ui';

const Container = styled(Stack)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const MessageLoader = () => {
  return (
    <Container justify="center" align="center">
      <Spinner size="medium" />
    </Container>
  );
};

export default MessageLoader;
