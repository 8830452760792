import React, { useMemo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { IChartProps } from '../../types';

ChartJS.register(ArcElement, Tooltip, Legend);
const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

const DoughnutChart = ({ labels, data, title, colors }: IChartProps) => {
  const payload = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: title,
          data,
          backgroundColor: colors,
        },
      ],
    }),
    [data, colors, labels, title]
  );

  return (
    <Doughnut
      style={{ padding: '10px', boxSizing: 'border-box' }}
      data={payload}
      options={options}
    />
  );
};

export default DoughnutChart;
