import React from 'react';
import { useDebugMode } from 'hooks';
import moment from 'moment';
import colors from 'theme/colors';
import { Stack, Switch, Text, Title } from 'ui';

/**
 * General about page
 */
const About = () => {
  const [isDebugModeEnabled, { toggleDebugMode }] = useDebugMode();

  return (
    <Stack gap="30px" margin="20px 0" align="start">
      <Title heading="h1">About</Title>
      <Stack id="debug-mode-field" align="start" gap="15px">
        <Text>Debug Mode:</Text>
        <Switch
          leftText="Off"
          rightText="On"
          checked={isDebugModeEnabled}
          onChange={toggleDebugMode}
        />
      </Stack>
      <Stack id="copyright-text-container" margin="40px 0">
        <Text size="xs" color={colors.gray}>
          &copy; AUTHENTISE {moment().year()}. ALL RIGHTS RESERVED{' '}
        </Text>
      </Stack>
    </Stack>
  );
};

export default About;
