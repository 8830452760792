export const CUSTOM_SUBSCRIPTION_PLANS = {
  Free: 'Free',
  Enterprise: 'Enterprise',
} as const;

export const SUBSCRIPTION_ERROR_KEYS = {
  THREAD_LIMIT_EXCEEDED: 'THREAD_LIMIT_EXCEEDED',
  USER_LIMIT_EXCEEDED: 'USER_LIMIT_EXCEEDED',
  CONTRIBUTOR_THREAD_LIMIT_EXCEEDED: 'CONTRIBUTOR_THREAD_LIMIT_EXCEEDED',
} as const;

export const CUSTOM_PAID_SUBSCRIPTION_PLANS = {
  CUSTOM_TRIAL: 'custom_trial',
  CUSTOM_PAID: 'custom_full',
} as const;
