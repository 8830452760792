import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { initialState, selectFilter, updateFilter } from 'modules/threads/filterSlice';
import { EThreadStatuses } from 'types';
import { Group, Text } from 'ui';
import StatusBadgeWithCircleIcon from '../badge/StatusBadgeWithCircleIcon';

interface IProps {
  type: EThreadStatuses;
  count: number;
  dateFrom: Date;
  cypressAttribute?: string;
}

const Label: React.FC<IProps> = ({ type, count, dateFrom, cypressAttribute }) => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);
  const isFiltered = filter.status === type && filter.date.includes(dateFrom);

  const handleClick = () => {
    const filterState = isFiltered
      ? { ...initialState }
      : { ...initialState, status: type, date: [dateFrom] };
    dispatch(updateFilter(filterState));
  };

  return (
    <Group
      align="center"
      style={{ cursor: 'pointer', fontWeight: isFiltered ? 600 : 400 }}
      onClick={handleClick}
      data-cy={cypressAttribute}
    >
      <StatusBadgeWithCircleIcon status={type} />
      <Text>{`(${count})`}</Text>
    </Group>
  );
};

export default Label;
