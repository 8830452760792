import { api } from 'app/api';
import { IPaginatedResponse } from 'types';
import { IResource, IResourcesResponse } from 'modules/resources/types';

const resourcesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getResources: build.query<IPaginatedResponse<IResourcesResponse>, string>({
      query: (id) => ({
        url: `/threads/${id}/resources/`,
      }),
      providesTags: ['resources'],
    }),
    createResource: build.mutation<IResourcesResponse, IResource>({
      query: (payload) => ({
        url: '/resources/',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['resources'],
    }),
    editResource: build.mutation<IResourcesResponse, IResourcesResponse>({
      query: ({ id, ...payload }) => ({
        url: `/resources/${id}/`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['resources'],
    }),
    deleteResource: build.mutation<void, number>({
      query: (id) => ({
        url: `/resources/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['resources'],
    }),
  }),
});

export const {
  useGetResourcesQuery,
  useCreateResourceMutation,
  useEditResourceMutation,
  useDeleteResourceMutation,
} = resourcesApi;
