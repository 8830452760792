export default {
  login: '/login',
  account: '/settings/account',
  members: '/settings',
  about: '/about',
  signup: '/sign-up',
  thread: '/thread',
  new_thread: '/new-thread',
  dashboard: '/',
  forgotPassword: '/forgot-password',
  checkEmail: '/check-email',
  resetPassword: '/reset',
  impersonate: '/impersonate',
  subscriptionsPlans: '/subscriptions-plans',
  createWorkspace: '/create-workspace',
};
