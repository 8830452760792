import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';
import { Stack, Text } from 'ui';
import colors from 'theme/colors';

interface ITextAreaProps {
  name: string;
  label?: string;
  error?: FieldError;
  resize?: string;
  maxLength?: number;
  [key: string]: any;
}

const StyledTextArea = styled.textarea<ITextAreaProps>`
  font-family: 'Inter', sans-serif;
  min-height: 100px;
  border: none;
  background-color: ${({ theme }) => theme.colors.dark2};
  border: ${({ error }) => `1px solid ${error ? 'red' : 'transparent'}`};
  color: white;
  resize: ${({ resize }) => resize || 'both'};
  border-radius: 4px;
  padding: 16px;

  &:focus {
    outline: none;
    box-shadow: 0 0 4px ${({ theme }) => theme.colors.blue};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.dark1};
    color: ${({ theme }) => theme.colors.gray1};
  }
`;
const TextArea: FC<ITextAreaProps> = ({
  label,
  name,
  error,
  register = () => null,
  children,
  maxLength,
  ...props
}) => {
  return (
    <Stack gap="8px">
      {label && (
        <label htmlFor={name}>
          <Text size="sm" color={colors.gray2}>
            {label}
          </Text>
        </label>
      )}
      <StyledTextArea
        maxLength={maxLength}
        {...props}
        id={name}
        name={name}
        rows={3}
        error={error}
        {...register(name)}
      >
        {children}
      </StyledTextArea>
      {error && (
        <Text size="xs" color="red">
          {error.message}
        </Text>
      )}
    </Stack>
  );
};

export default TextArea;
