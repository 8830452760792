import styled from 'styled-components';
import { Group } from 'ui';

export const Wrapper = styled(Group)`
  border: 2px dashed ${({ theme }) => theme.colors.blue2};
  border-radius: 8px;
  padding: 12px;
  width: 100%;
`;

export const ImgWrapper = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
