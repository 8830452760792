import React, { ReactElement } from 'react';
import { Group, Text } from 'ui';
import colors from 'theme/colors';
import * as Layout from './Layout';

interface IRadioButton {
  id: string;
  name: string;
  value?: string;
  checked?: boolean;
  text: string;
  icon?: ReactElement;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: React.FC<IRadioButton> = ({
  id,
  name,
  value,
  onChange,
  checked,
  text,
  icon,
  ...props
}) => {
  return (
    <Layout.Label htmlFor={id}>
      <Group gap="16px" role="radio" align="center">
        <Layout.Input
          id={id}
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          {...props}
        />
        <Layout.CustomRadio />
        <Group gap="8px" align="center">
          {icon && icon}
          <Text color={colors.gray2} weight="600">
            {text}
          </Text>
        </Group>
      </Group>
    </Layout.Label>
  );
};

export default RadioButton;
