import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'modules/resources/components/Layout';
import { useModal } from 'modules/modals/ModalProvider';
import { EPermissions } from 'app/permissions/constants';
import { Icon, DropDownAction, Stack, Text } from 'ui';
import { IResourcesResponse } from 'modules/resources/types';
import colors from 'theme/colors';
import AccessControl from 'app/permissions/AccessControl';

const ResourceCard = (props: IResourcesResponse) => {
  const { id, name, unit, amount } = props;
  const { open } = useModal();

  const handleEdit = () => {
    open({
      variant: 'fullRight',
      contentLabel: 'Edit Resource',
      name: 'Edit Resource',
      modal: 'createResource',
      context: {
        initialValue: props,
      },
      id: 'modal-resource',
    });
  };

  const handleRemove = () => {
    open({
      variant: 'center',
      contentLabel: 'delete resource',
      name: 'Delete Resource',
      modal: 'deleteResource',
      context: {
        id,
        name,
        amount,
        unit,
      },
      id: 'modal-delete-resource',
    });
  };

  const actions = useMemo(
    () => [
      {
        id: 'edit',
        title: 'Edit',
        action: handleEdit,
        icon: <Icon size="small" icon="PencilIcon" />,
        permissions: [EPermissions.EDIT_RESOURCES],
      },
      {
        id: 'delete',
        title: 'Delete',
        action: handleRemove,
        icon: <Icon size="small" icon="BinIcon" />,
        permissions: [EPermissions.DELETE_RESOURCES],
      },
    ],
    [props]
  );

  const { id: threadId } = useParams();

  return (
    <Card align="center" justify="space-between" data-cy="resource-card">
      <Stack>
        <Text weight="500" cypressAttribute="resource-card-name-text">
          {name}
        </Text>
        <Text color={colors.gray3} cypressAttribute="resource-card-amount-text">
          {amount} {unit}
        </Text>
      </Stack>
      <AccessControl
        permissions={[EPermissions.EDIT_RESOURCES, EPermissions.DELETE_RESOURCES]}
        threadId={threadId as string}
        strict={false}
      >
        <DropDownAction
          position="horizontal"
          actions={actions}
          cypressAttribute="resource-card-dropdown-actions"
        />
      </AccessControl>
    </Card>
  );
};

export default ResourceCard;
