import { api } from 'app/api';
import { ICreateWorkspaceMutation, ICreateWorkspaceResponse } from './types';

export const createWorkspaceApi = api.injectEndpoints({
  endpoints: (build) => ({
    createWorkspace: build.mutation<ICreateWorkspaceResponse, ICreateWorkspaceMutation>({
      query: (body) => ({
        url: `sites/create-magic-link/`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useCreateWorkspaceMutation } = createWorkspaceApi;
