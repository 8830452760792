import { EPermissions, ERoles, PERMISSIONS_PER_ROLE } from './constants';
import { EUserRole, IUser } from '../../types/user';

export const checkPermissionsStrict = (permissions: EPermissions[], role: ERoles) => {
  return permissions.every((permission) => PERMISSIONS_PER_ROLE[role].includes(permission));
};

export const checkPermissions = (permissions: EPermissions[], role: ERoles) => {
  return permissions.some((permission) => PERMISSIONS_PER_ROLE[role].includes(permission));
};

export const getRole = (userRole: EUserRole, isOwner: boolean, isContributor: boolean): ERoles => {
  if (isOwner) return ERoles.OWNER;
  switch (userRole) {
    case EUserRole.ADMIN:
      return ERoles.OWNER;
    case EUserRole.USER:
      return isContributor ? ERoles.CONTRIBUTOR : ERoles.VIEW_ONLY;
    default:
      return isContributor ? ERoles.GUEST_CONTRIBUTOR : ERoles.VIEW_ONLY;
  }
};

export const hasAccess = (
  permissions: EPermissions[],
  user: IUser,
  threadOwnerId: string,
  isContributor: boolean,
  strict: boolean
) => {
  const { id, userRole } = user;
  return strict
    ? checkPermissionsStrict(permissions, getRole(userRole, id === threadOwnerId, isContributor))
    : checkPermissions(permissions, getRole(userRole, id === threadOwnerId, isContributor));
};
