import { api } from 'app/api';
import { IPaginatedResponse } from 'types';
import {
  ICreateInviteUsersMutation,
  ICreateInviteUsersResponse,
  IGetUserInvitesParams,
  IUserInvites,
} from './types';

export const inviteUsersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserInvitesList: build.query<IPaginatedResponse<IUserInvites>, IGetUserInvitesParams>({
      query: ({ ordering, limit, offset }) => ({
        url: `/invite-tokens/?ordering=${ordering}&limit=${limit}&offset=${offset}&status=pending`,
      }),
      providesTags: ['userInvites'],
    }),

    inviteUsers: build.mutation<ICreateInviteUsersResponse[], ICreateInviteUsersMutation[]>({
      query: (payload) => ({
        url: '/invite-tokens/',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['userInvites'],
    }),

    deleteMember: build.mutation<void, string>({
      query: (id) => ({
        url: `/invite-tokens/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['userInvites', 'contributorsInvites'],
    }),
  }),
});

export const { useGetUserInvitesListQuery, useInviteUsersMutation, useDeleteMemberMutation } =
  inviteUsersApi;
