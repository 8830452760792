import { CSSProperties } from 'react';
import colors from './colors';

export interface IHeading {
  h1: CSSProperties;
  h5: CSSProperties;
  h6: CSSProperties;
}
export interface ITypography {
  xs: CSSProperties;
  sm: CSSProperties;
  md: CSSProperties;
  lg: CSSProperties;
}

interface ITheme {
  heading: IHeading;
  typography: ITypography;
  colors: typeof colors;
  primaryColor: string;
}

const theme: ITheme = {
  heading: {
    h1: {
      fontSize: '40px',
      lineHeight: '40px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '24px',
      lineHeight: '24px',
    },
    h6: {
      fontSize: '18px',
      lineHeight: '26px',
    },
  },
  typography: {
    xs: {
      fontSize: '12px',
      lineHeight: '12px',
    },
    sm: {
      fontSize: '14px',
      lineHeight: '14px',
    },
    md: {
      fontSize: '16px',
      lineHeight: '16px',
    },
    lg: {
      fontSize: '18px',
      lineHeight: '18px',
    },
  },
  colors,
  primaryColor: colors.orange,
};

export default theme;
