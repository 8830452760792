import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button, Spinner, Stack, TextInput } from 'ui';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import routes from 'constants/routes';
import { validationSchema } from 'utils/validation';
import { useResetConfirmEmailMutation } from 'modules/auth/authApi';
import useLocalStorage from 'hooks/useLocalStorage';
import { RESET_EMAIL } from '../../constants';

const validation = yup.object({
  email: validationSchema.emailRequired(),
});

const Form = () => {
  const navigate = useNavigate();
  const [_, setKey] = useLocalStorage(RESET_EMAIL);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(validation),
  });
  const [confEmail, { isLoading }] = useResetConfirmEmailMutation();

  const onSubmit = ({ email }: FieldValues) => {
    setKey(email);
    confEmail({ email }).then(() => navigate(routes.checkEmail));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="40px">
        <TextInput error={errors.email} label="Email" name="email" register={register} />
        <Button disabled={isLoading} type="submit">
          {isLoading ? <Spinner size="small" /> : 'Send Reset Link'}
        </Button>
      </Stack>
    </form>
  );
};

export default Form;
