import { useAppDispatch, useAppSelector } from 'app/hooks';
import { showSuccessMessage } from 'modules/alert/utils';
import { selectDebugModeEnabled, setDebugMode } from 'modules/app/appSlice';

type TUseDebugMode = [boolean, { toggleDebugMode: () => void }];

const useDebugMode = (): TUseDebugMode => {
  const dispatch = useAppDispatch();
  const isDebugModeEnabled = useAppSelector(selectDebugModeEnabled) as boolean;

  const toggleDebugMode = () => {
    dispatch(setDebugMode(!isDebugModeEnabled));
    showSuccessMessage(`Debug mode ${isDebugModeEnabled ? 'disabled' : 'enabled'}.`);
  };

  return [isDebugModeEnabled, { toggleDebugMode }];
};

export default useDebugMode;
