import { FieldError } from 'react-hook-form';

export enum EVariant {
  PRIORITY = 'priority',
  STATUS = 'status',
  EVENT = 'event',
}

export type TVariant = 'priority' | 'status' | 'event';

export interface IProps {
  width?: string;
  bg?: string;
  variant?: TVariant;
  disabled?: boolean;
}

export interface IDropdownProps {
  options: IOption[];
  name: string;
  defaultValue?: IOption | null;
  badge: TVariant;
  placeholder: string;
  error?: FieldError;
  label?: string;
  width?: string;
  bg?: string;
  required?: boolean;
  onClick?: (val: string) => React.MouseEventHandler | void;
  disabled?: boolean;
  cypressAttribute?: string;
}

export interface IOption {
  optionLabel: string;
  value: string;
}

export interface ITitle {
  badge?: TVariant;
  placeholder: string;
  value: any;
}
