import { CSSProperties } from 'react';
import styled, { CSSProp, css } from 'styled-components';
import colors from 'theme/colors';
import { mapStyledProps } from 'utils/helpers';

export const Card = styled.div<{
  cursorPointer?: boolean;
  backgroundColor?: CSSProperties['backgroundColor'];
  padding?: CSSProperties['padding'];
  width?: CSSProperties['width'];
  maxWidth?: CSSProperties['maxWidth'];
  height?: CSSProperties['height'];
  maxHeight?: CSSProperties['maxHeight'];
  borderRadius?: CSSProperties['borderRadius'];
  [key: string]: any;
}>`
  cursor: ${({ cursorPointer = false }) => (cursorPointer ? 'pointer' : 'normal')};
  background-color: ${({ backgroundColor }) => backgroundColor || colors.blue5};
  padding: ${({ padding }) => padding || `20px 30px`};
  border-radius: ${({ borderRadius }) => borderRadius || 0};
  width: ${({ width }) => width || 'fit-content'};
  max-width: ${({ maxWidth }) => maxWidth || '480px'};
  height: ${({ height }) => height || '100%'};
  max-height: ${({ maxHeight }) => maxHeight || '100%'};
`;

export const CardHeader = styled.div<{
  titleStyle?: string;
  [key: string]: any;
}>`
  font-size: ${({ titleStyle = true }) => (titleStyle ? '20px' : 'inherit')};
  ${(props) => mapStyledProps(props)}
`;

export const CardBody = styled.div<{
  backgroundColor?: CSSProperties['backgroundColor'];
  [key: string]: any;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || 'inherit'};
  ${(props) => mapStyledProps(props)}
`;
