import React from 'react';
import { NavLink } from 'react-router-dom';
import { Stack, Title, Icon } from 'ui';
import routes from 'constants/routes';
import TabLink from 'pages/members/components/TabLink';
import colors from 'theme/colors';
import ProfileForm from 'modules/user/components/ProfileForm';
import styled from 'styled-components';
import devices from 'theme/devices';

const Section = styled(Stack)`
  gap: 20px;
  @media screen and ${devices.laptop} {
    flex-direction: row;
    gap: 70px;
  }
`;

const Account: React.FC = () => {
  return (
    <Stack gap="64px">
      <Title heading="h5" weight="600" cypressAttribute="account-page-title">
        Profile
      </Title>
      <Section>
        <Stack style={{ minWidth: '240px' }} gap="20px">
          <NavLink to={routes.account} data-cy="personal-account-nav-link">
            <TabLink active>
              <Icon icon="InfoIcon" stroke={colors.orange} /> Personal Info
            </TabLink>
          </NavLink>
        </Stack>
        <ProfileForm />
      </Section>
    </Stack>
  );
};

export default Account;
