import styled from 'styled-components';
import { Group, Stack } from 'ui';
import colors from 'theme/colors';

export const CircleBig = styled.div`
  width: 1162px;
  bottom: -400px;
  right: -400px;
  height: 1162px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgba(255, 195, 167, 0.25);
`;

export const CircleSmall = styled(CircleBig)`
  bottom: -270px;
  right: -270px;
  width: 925px;
  height: 925px;
`;

export const CircleFilled = styled(CircleBig)`
  background-color: rgba(233, 107, 24, 0.1);
  width: 775px;
  height: 775px;
  bottom: -200px;
  right: -200px;
`;

export const TextLayer = styled(Stack)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 70px 90px 0;
  z-index: 30;
`;

export const RightSide = styled(Group)`
  overflow: hidden;
  flex: 1;
  position: relative;
  background-color: ${colors.dark2};
`;
