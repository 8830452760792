import React from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import SvgLogo from '!file-loader!assets/icons/Logo_header.svg';

interface IProps {
  width?: number;
  height?: number;
  className?: string;
}

/** @description General component for app logo. */
const Logo = ({ width = 24, height = 24, className }: IProps) => (
  <img src={SvgLogo} className={className} alt="logo" width={width} height={height} />
);

export default Logo;
