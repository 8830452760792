import React, { useEffect, useRef, useState } from 'react';
import colors from 'theme/colors';
import { showErrorMessage } from 'modules/alert/utils';
import { NOTIFICATION } from 'modules/alert/constants';
import { Group, Text, Stack, Button, Icon } from 'ui';
import { FILE_PARAMS } from 'constants/index';
import UploadPlaceholder from 'assets/icons/upload_placeholder.svg';
import { convertToBase64 } from 'utils/helpers';
import * as Layout from './Layout';
import { IFileLoaderProps } from './types';

const FileLoader: React.FC<IFileLoaderProps> = ({
  label,
  text,
  name,
  icon,
  register = () => null,
  size = FILE_PARAMS.SIZE,
  preview,
  accept = FILE_PARAMS.ACCEPT,
  onChange,
  value,
}) => {
  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string | undefined>(value || UploadPlaceholder);

  const dropzoneRef = useRef<HTMLDivElement>();

  /**
   * @description Returns whether the uploaded file(s) are proper files.
   * @param {DragEvent}
   * @returns {boolean}
   */
  const draggedItemIsFile = (event: DragEvent): boolean => {
    if (!event.dataTransfer) {
      return false;
    }

    return event.dataTransfer?.types.includes('Files');
  };

  const handleDragEnter = (event: DragEvent) => {
    event.preventDefault();

    if (!draggedItemIsFile(event)) {
      return;
    }

    setIsDraggingOver(true);
  };

  const handleDragLeave = (event: DragEvent) => event.preventDefault();

  const handleDrop = (event: DragEvent) => {
    setIsDraggingOver(false);
    event.preventDefault();

    // Ignore the drop event if the target is outside the drop zone component
    const targetElement = event.target as HTMLDivElement;

    if (!draggedItemIsFile(event)) {
      return;
    }

    if (!dropzoneRef?.current) {
      console.error('Dropzone file upload failed, element could not be found.');
      return;
    }

    if (targetElement !== dropzoneRef.current && !dropzoneRef.current.contains(targetElement)) {
      return;
    }

    const file = Array.from(event.dataTransfer?.files || [])[0];

    if (file.size > size) {
      showErrorMessage(NOTIFICATION.MAX_SIZE_UPLOAD);
      return;
    }

    if (preview) {
      convertToBase64(file, setImgSrc);
    }

    onChange(file);
  };

  useEffect(() => {
    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('dragover', (event: DragEvent) => event.preventDefault());
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('dragover', (event: DragEvent) => event.preventDefault());
      window.removeEventListener('drop', handleDrop);
    };
  });

  const handleChange = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    if (!target.files) return;
    const file = target.files[0];

    if (file.size > size) {
      showErrorMessage(NOTIFICATION.MAX_SIZE_UPLOAD);
      target.value = '';
      return;
    }

    if (preview) {
      convertToBase64(file, setImgSrc);
    }
    onChange(file);
  };

  return (
    <Layout.Wrapper
      ref={dropzoneRef as React.RefObject<HTMLDivElement>}
      justify="space-between"
      align="center"
      data-cy="file-loader-wrapper"
    >
      <Group align="center" gap="10px" minWidth={350} data-cy="file-loader-wrapper-group">
        {icon && icon}

        {preview && (
          <Layout.ImgWrapper>
            <img src={imgSrc} alt="ava" />
          </Layout.ImgWrapper>
        )}

        <Stack gap="8px">
          {label && <Text>{isDraggingOver ? 'Drop your file here' : label}</Text>}
          {text && (
            <Text size="xs" color={colors.dark4}>
              {text}
            </Text>
          )}
        </Stack>
      </Group>

      <Button
        rightIcon={<Icon icon="UploadIcon" size="normal" />}
        color={colors.dark4}
        variant="outlined"
        style={{ position: 'relative', fontWeight: '600', padding: '11px 16px' }}
        cypressAttribute="file-loader-wrapper-browse-btn"
      >
        Browse
        <Layout.Input
          name={name}
          {...register(name)}
          accept={accept}
          type="file"
          onChange={handleChange}
          data-cy="file-loader-wrapper-file-input"
        />
      </Button>
    </Layout.Wrapper>
  );
};

export default FileLoader;
