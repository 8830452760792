import { EEvents } from 'constants/events';

export default {
  [EEvents.ISSUE]: '#8B8B8E',
  [EEvents.QUESTION]: '#895725',
  [EEvents.INSIGHT]: '#6B83FF',
  [EEvents.DECISION]: '#D57232',
  [EEvents.RESOLUTION]: '#A1C380',
  [EEvents.ACTION]: '#7C66BD',
  [EEvents.STOPPED_STALLED]: '#B73131',
  [EEvents.END]: '#8B8B8E',
};
