import styled from 'styled-components';

interface IDividerProps {
  color?: string;
}

const Divider = styled.div<IDividerProps>`
  border: 1px solid ${({ theme, color }) => color ?? theme.colors.dark3};
`;

export default Divider;
