import React from 'react';
import styled from 'styled-components';

interface ITagBadge {
  color?: string;
  size?: TSize;
  children: React.ReactNode;
  cypressAttribute?: string;
}

type TSize = 'sm' | 'xs';

enum ESize {
  SM = 'sm',
  XS = 'xs',
}

const SIZE_MAPPING = {
  [ESize.SM]: {
    fontSize: '14px',
    padding: '4px 10px',
  },
  [ESize.XS]: {
    fontSize: '10px',
    padding: '0 6px',
  },
};

const Badge = styled.div<{ size?: TSize; color?: string }>`
  flex-shrink: 0;
  height: max-content;
  padding: ${({ size }) => SIZE_MAPPING[size || ESize.SM].padding};
  border-radius: 40px;
  background: rgba(46, 56, 90, 0.5);
  font-size: 14px;
  white-space: nowrap;
  color: white;
  line-height: 17px;
  width: max-content;
  max-width: 140px;
  white-space: nowrap;
  background: ${({ color }) => color || 'rgba(46, 56, 90, 0.5)'};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ size }) => SIZE_MAPPING[size || ESize.SM].fontSize};
`;

const TagBadge: React.FC<ITagBadge> = ({ children, size, color, cypressAttribute }) => {
  return (
    <Badge size={size} color={color} data-cy={cypressAttribute}>
      {children}
    </Badge>
  );
};

export default TagBadge;
