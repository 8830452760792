import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IId, IPaginatedResponse } from 'types';
import { resetAll } from 'app/api';
import { EChatFilter, IMessageResponse, IPrependPayload, IMessageLoad } from './types';
import { chatApi } from './chatApi';
import { updateMessage as updateMessageUtil, pushMessage as pushMessageUtil } from './utils';
import { MESSAGE_PER_PAGE } from './constants';

interface IChatState {
  messages: IMessageResponse[];
  count: number;
  isLoading: boolean;
  filterBy: EChatFilter;
  startIndex: number;
  nextLink: string | null;
  prevLink: string | null;
  currentOffset: number | null;
}

interface ILoadThunkArgs {
  offset: number;
  limit: number;
  id: string;
  type: EChatFilter;
}

export const initialState: IChatState = {
  messages: [],
  isLoading: false,
  count: 0,
  filterBy: EChatFilter.COMMENTS,
  startIndex: 0,
  nextLink: null,
  prevLink: null,
  currentOffset: null,
};

export const prependLoadMessage = createAsyncThunk(
  'message/prependLoad',
  async ({ offset, limit, id, type }: ILoadThunkArgs, { dispatch }) => {
    const result = dispatch(
      chatApi.endpoints.getMessages.initiate({
        id,
        offset,
        limit,
        type,
      })
    );
    result.unsubscribe();
    const response = await result;
    return {
      data: response.data as IPaginatedResponse<IMessageResponse>,
      index: offset,
    };
  }
);

export const loadPrevMessagesByLink = createAsyncThunk(
  'message/loadPrevByLink',
  async ({ link }: { link: string | null }, { dispatch }) => {
    const result = dispatch(chatApi.endpoints.getMessages.initiate({ link }));
    result.unsubscribe();
    const response = await result;
    return response.data as IPaginatedResponse<IMessageResponse>;
  }
);

export const loadNextMessagesByLink = createAsyncThunk(
  'message/loadNextByLink',
  async ({ link }: { link: string | null }, { dispatch }) => {
    const result = dispatch(chatApi.endpoints.getMessages.initiate({ link }));
    result.unsubscribe();
    const response = await result;
    return response.data as IPaginatedResponse<IMessageResponse>;
  }
);

export const loadNextMessage = createAsyncThunk(
  'message/loadNext',
  async ({ offset, limit, id, type }: ILoadThunkArgs, { dispatch }) => {
    const result = dispatch(chatApi.endpoints.getMessages.initiate({ id, offset, limit, type }));
    result.unsubscribe();
    const response = await result;
    return response.data as IPaginatedResponse<IMessageResponse>;
  }
);

export const loadMessages = createAsyncThunk(
  'message/loadCount',
  async (args: IMessageLoad, { dispatch }) => {
    const { msgId, ...rest } = args;
    const result = dispatch(chatApi.endpoints.loadInitialMessage.initiate(msgId ? args : rest));
    const response = await result;
    result.unsubscribe();
    return response.data as IPaginatedResponse<IMessageResponse>;
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    updateMessages: (state, { payload }: PayloadAction<IPaginatedResponse<IMessageResponse>>) => ({
      ...state,
      messages: payload.results,
      count: payload.count,
    }),
    updateMessage: (state, { payload }: PayloadAction<IMessageResponse>) => {
      state.messages = updateMessageUtil(state.messages, payload);
      return state;
    },
    updateFilter: (state, { payload }: PayloadAction<EChatFilter>) => ({
      ...state,
      filterBy: payload,
    }),
    pushMessage: (state, { payload }: PayloadAction<IMessageResponse>) => {
      state.messages = pushMessageUtil(state.messages, payload);
    },
    deleteMessage: (state, { payload }: PayloadAction<IId>) => {
      state.messages = state.messages
        .filter((message) => message.id !== payload.id)
        .map((msg) => {
          msg.replies = msg.replies.filter((reply) => reply.id !== payload.id);
          return msg;
        });
    },
    updateIndex: (state, { payload }: PayloadAction<number>) => {
      state.startIndex = payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        prependLoadMessage.fulfilled,
        (state, { payload }: PayloadAction<IPrependPayload>) => {
          state.messages = [...payload.data.results, ...state.messages];
          state.isLoading = false;
          state.startIndex = payload.index;
          state.nextLink = payload.data.next;
          state.prevLink = payload.data.previous;
          return state;
        }
      )
      .addCase(loadPrevMessagesByLink.fulfilled, (state, { payload, meta }) => {
        state.messages = [...payload.results, ...state.messages];
        state.isLoading = false;
        state.prevLink = payload.previous;
        state.currentOffset = Number(new URLSearchParams(meta.arg.link || '').get('offset'));
        return state;
      })
      .addCase(loadNextMessagesByLink.fulfilled, (state, { payload, meta }) => {
        state.messages = [...state.messages, ...payload.results];
        state.isLoading = false;
        state.nextLink = payload.next;
        state.currentOffset = Number(new URLSearchParams(meta.arg.link || '').get('offset'));
        return state;
      })
      .addCase(loadPrevMessagesByLink.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(loadNextMessagesByLink.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(prependLoadMessage.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(
        loadNextMessage.fulfilled,
        (state, { payload }: PayloadAction<IPaginatedResponse<IMessageResponse>>) => {
          state.messages = [...state.messages, ...payload.results];
          state.isLoading = false;
          return state;
        }
      )
      .addCase(loadNextMessage.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(loadMessages.fulfilled, (state, { payload }) => {
        const prevOffset = new URLSearchParams(payload.previous || '').get('offset');
        const nextOffset = new URLSearchParams(payload.next || '').get('offset');
        let currentOffset = prevOffset ? Number(prevOffset) : Number(nextOffset) - MESSAGE_PER_PAGE;
        if (currentOffset < 0) {
          currentOffset = payload.count - MESSAGE_PER_PAGE;
        }
        state.count = payload.count;
        state.startIndex = payload.count - payload.results.length;
        state.messages = payload.results;
        state.isLoading = false;
        state.nextLink = payload.next;
        state.prevLink = payload.previous;
        state.currentOffset = currentOffset;
        return state;
      })
      .addCase(loadMessages.pending, (state, { payload }) => {
        state.isLoading = true;
        return state;
      })
      .addCase(resetAll, () => initialState)
      .addMatcher(
        chatApi.endpoints.editMessages.matchFulfilled,
        (state, { payload }: PayloadAction<IMessageResponse>) => {
          state.messages = updateMessageUtil(state.messages, payload);
          return state;
        }
      )
      .addMatcher(
        chatApi.endpoints.sendMessages.matchFulfilled,
        (state, { payload }: PayloadAction<IMessageResponse>) => {
          if (state.filterBy !== EChatFilter.LOGS) {
            state.messages = updateMessageUtil(state.messages, {
              ...payload,
              isLoading: false,
              preloadFiles: [],
            });
          }
          return state;
        }
      )
      .addMatcher(
        chatApi.endpoints.likeMessages.matchFulfilled,
        (state, { payload }: PayloadAction<IMessageResponse>) => {
          state.messages = updateMessageUtil(state.messages, payload);
        }
      ),
});

export const selectMessages = (state: RootState) => state.chat.messages;
export const selectFilter = (state: RootState) => state.chat.filterBy;
export const selectLoadingStatus = (state: RootState) => state.chat.isLoading;
export const selectCount = (state: RootState) => state.chat.count;
export const selectChat = (state: RootState) => state.chat;

export const { updateMessages, updateFilter, pushMessage, deleteMessage, updateMessage, reset } =
  chatSlice.actions;

export default chatSlice.reducer;
