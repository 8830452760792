import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { useWindowSize } from 'hooks/useWindowSize';
import { Group, Stack, Title } from '../ui';
import Logo from '../assets/icons/Logo.svg';
import { mediaSize } from '../theme/devices';
import * as Layout from '../pages/login/components/Layout';
import CartImg from '../pages/login/assets/ChartBar.png';
import * as AppLayout from './App';

const FormWrapper = styled(AppLayout.Container)`
  max-width: 500px;
`;

const Login: FC<{ children: ReactElement }> = ({ children }) => {
  const size = useWindowSize();

  return (
    <Group style={{ height: '100vh' }} fullHeight>
      <Stack style={{ flex: 1 }} justify="center" align="center">
        <FormWrapper>
          <Stack gap="45px">
            <img width={260} src={Logo} alt="logo" />
            {children}
          </Stack>
        </FormWrapper>
      </Stack>
      {size.width > mediaSize.laptop && (
        <Layout.RightSide justify="end" align="end">
          <Layout.TextLayer gap="15px" fluid fullHeight>
            <Title heading="h5" style={{ lineHeight: '38px' }}>
              Enable cross functional agile teams to more easily work together on common
              work-threads
            </Title>
            <Title heading="h6" style={{ opacity: 0.6 }}>
              Capture ideas... Share Insights... Own work-threads... Mint decisions... Commit
              actions... Drive Focus.... Iterate fast
            </Title>
          </Layout.TextLayer>
          <Layout.CircleBig />
          <Layout.CircleSmall />
          <Layout.CircleFilled />
          <img src={CartImg} style={{ position: 'relative', width: '80%' }} alt="chart" />
        </Layout.RightSide>
      )}
    </Group>
  );
};

export default Login;
