import React from 'react';
import { IGanttTaskHeader } from 'modules/threads/types';
import { GanttTable, GanttTableHeader, GanttHeaderItem } from './Layout';

const TaskListHeader: React.FC<IGanttTaskHeader> = ({
  headerHeight,
  fontFamily,
  fontSize,
  rowWidth,
}) => {
  return (
    <GanttTable fontFamily={fontFamily} fontSize={fontSize}>
      <GanttTableHeader headerHeight={headerHeight}>
        <GanttHeaderItem rowWidth={rowWidth}>&nbsp;Name</GanttHeaderItem>
      </GanttTableHeader>
    </GanttTable>
  );
};

export default TaskListHeader;
