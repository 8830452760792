import React, { FC } from 'react';
import FileBadge from './File';
import { MediaContainer } from './Layout';

interface IMediaProps {
  files: File[];
  removeFile?: (fileName: string) => void;
}

const MediaBlock: FC<IMediaProps> = ({ files, removeFile }) => {
  return (
    <MediaContainer fluid data-cy="media-container">
      {files.map((file) => (
        <FileBadge onRemove={removeFile} key={file.name} name={file.name} />
      ))}
    </MediaContainer>
  );
};

export default MediaBlock;
