import { IModalOptions } from 'modules/modals/types';

export const handleOpenCreateThreadModal = (open: (options: IModalOptions) => void) => {
  open({
    variant: 'fullRight',
    contentLabel: 'Create new thread',
    name: 'New Thread',
    modal: 'createThread',
    id: 'modal-create-thread',
  });
};
