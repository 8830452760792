import React, { FC } from 'react';
import { Stack, Text } from 'ui';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

interface IProps {
  error: FetchBaseQueryError;
  children: React.ReactNode;
}

const ModalErrorBoundary: FC<IProps> = ({ error, children }) => {
  const getErrorTextByStatus = (
    status: number | 'FETCH_ERROR' | 'PARSING_ERROR' | 'TIMEOUT_ERROR' | 'CUSTOM_ERROR'
  ) => {
    switch (status) {
      case 403:
        return "You don't have permission to perform this action.";
      case 404:
        return 'The content you are looking for does not exist.';
      case 500:
        return 'An error occurred while processing your request. Please try again later.';
      default:
        return 'An error occurred while processing your request. Please try again later.';
    }
  };

  if (error) {
    return (
      <Stack align="center" justify="center" style={{ padding: '5rem' }}>
        <Text size="lg">{getErrorTextByStatus(error.status)}</Text>
      </Stack>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default ModalErrorBoundary;
