import React from 'react';
import { Avatar, Group, Icon, Stack, Text } from 'ui';
import { useModal } from 'modules/modals/ModalProvider';
import * as Layout from 'pages/members/components/Layout';
import { useAppSelector } from 'app/hooks';
import { selectUser } from 'modules/auth/userSlice';
import { EUserRole } from 'types/user';
import _capitalize from 'lodash/capitalize';
import { IUserInvites } from '../../../modules/inviteUsers/types';

interface InviteCardProps {
  invite: IUserInvites;
}

export const InviteCard: React.FC<InviteCardProps> = ({ invite }) => {
  const { open } = useModal();

  const {
    userInfo: { userRole: currentUserRole },
  } = useAppSelector(selectUser);

  const handleRemove = () => {
    open({
      variant: 'center',
      contentLabel: 'delete invite',
      name: 'Delete Invite',
      modal: 'deleteInvite',
      context: {
        invite,
      },
      id: 'modal-delete-invite',
    });
  };

  return (
    <Layout.CardUser data-cy="member-card-user">
      <Group align="center" gap="12px">
        <Avatar cypressAttribute="member-card-avatar" />
        <Stack gap="10px">
          <Text weight="700" cypressAttribute="member-card-user-email">
            {invite.email}
          </Text>
        </Stack>
      </Group>

      <Layout.ListControls>
        {currentUserRole === EUserRole.ADMIN && (
          <Layout.RoleContainer>
            <Text
              weight="500"
              align="start"
              size="md"
              cypressAttribute="member-card-user-role-status"
            >
              {_capitalize(invite.status)}
            </Text>
          </Layout.RoleContainer>
        )}
        <Layout.RemoveButton
          variant="plain"
          onClick={handleRemove}
          data-cy="member-card-user-remove-btn"
        >
          <Icon icon="CloseIcon" size="medium" path="#fff" />
        </Layout.RemoveButton>
      </Layout.ListControls>
    </Layout.CardUser>
  );
};
