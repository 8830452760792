import styled, { css } from 'styled-components';
import Stack from '../stack';
import { TDropDownPosition } from './types';

const positionLeft = css`
  left: 0;
`;
const positionRight = css`
  right: 0;
`;

const positionMap = {
  left: positionLeft,
  right: positionRight,
};

export const DropDown = styled(Stack)<{ position: TDropDownPosition }>`
  position: absolute;
  z-index: 100;
  top: 110%;
  padding: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.dark5};
  ${({ position }) => positionMap[position]}
  width: max-content;
`;
export const Control = styled.div<{ disabled: boolean }>`
  display: inline-block;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;
