import { AVAILABLE_INTEGRATIONS, INTEGRATION_LIST } from 'constants/integrations';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { useUpdateIntegrationsMutation } from 'modules/integrations/integrationsApi';
import { useModal } from 'modules/modals/ModalProvider';
import React, { FC } from 'react';
import colors from 'theme/colors';
import { Text, Stack, Group, Button, Spinner } from 'ui';
import { handleApiCall } from 'utils/helpers';

interface IProps {
  integrationName: keyof typeof AVAILABLE_INTEGRATIONS;
}

const RemoveIntegration: FC<IProps> = ({ integrationName }) => {
  const { close } = useModal();
  const { title } = INTEGRATION_LIST[integrationName];

  const [removeIntegration, { isLoading }] = useUpdateIntegrationsMutation();

  const handleRemoveIntegration = async () => {
    const response = await removeIntegration({ [integrationName]: false });
    handleApiCall(
      response,
      () => showErrorMessage('Could not remove integration'),
      () => {
        showSuccessMessage('Integration removed successfully');
        close();
      }
    );
  };
  return (
    <Stack gap="30px">
      <Text weight="500" align="center" size="lg">
        Are you sure you want to remove {title} integration from active?
      </Text>
      <Text size="md" weight="500" style={{ lineHeight: 'normal' }}>
        Your users will no longer be able to connect to {title} when you perform this action.
      </Text>
      <Group gap="25px">
        <Button color={colors.red} fluid onClick={handleRemoveIntegration} disabled={isLoading}>
          {isLoading ? <Spinner size="small" color={colors.white} /> : 'Remove'}
        </Button>
        <Button color={colors.dark3} fluid onClick={close}>
          Cancel
        </Button>
      </Group>
    </Stack>
  );
};

export default RemoveIntegration;
