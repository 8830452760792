import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'hooks';
import { IMenuProps } from './types';
import * as Layout from './Layout';

const Menu = <T extends { id: string | number }>({
  data,
  Item,
  children,
  dropDownPosition = 'left',
  style,
  disabled = false,
  onClose,
  cypressAttribute,
}: IMenuProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const controlRef = useRef(null);

  const dataItemClickHandler = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(false);
    onClose?.();
  };

  const outsideControlClickHandler = () => {
    setIsOpen(false);
    onClose?.();
  };

  useOnClickOutside(controlRef, outsideControlClickHandler);

  return (
    <Layout.Control
      disabled={disabled}
      style={style}
      onClick={() => setIsOpen(!isOpen)}
      ref={controlRef}
      data-cy={cypressAttribute}
    >
      {children}
      {isOpen && (
        <Layout.DropDown
          position={dropDownPosition}
          data-cy={cypressAttribute && `${cypressAttribute}-list`}
        >
          {data.map((props) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
              key={props.id}
              onClick={dataItemClickHandler}
              data-cy={cypressAttribute && `${cypressAttribute}-list-item`}
            >
              <Item key={props.id} {...props} />
            </div>
          ))}
        </Layout.DropDown>
      )}
    </Layout.Control>
  );
};

export default Menu;
