import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { MAIN_BOTTOM_PADDING } from '../../constants';
import Stack from '../stack';

interface IProps {
  children: ReactElement;
}

const ScreenContent: FC<IProps> = ({ children }) => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const contentHeight = `calc(100vh - ${height + MAIN_BOTTOM_PADDING}px)`;

  useEffect(() => {
    if (ref?.current) {
      setHeight(ref.current.getBoundingClientRect().top);
    }
  }, [ref?.current]);

  return (
    <Stack fluid style={{ height: contentHeight }} ref={ref}>
      {children}
    </Stack>
  );
};

export default ScreenContent;
