import styled, { css } from 'styled-components';
import Stack from '../stack';
import Group from '../group';
import { IDropdownProps } from './types';

const positionLeft = css`
  left: 0;
`;
const positionRight = css`
  right: 0;
`;

const positionMap = {
  left: positionLeft,
  right: positionRight,
};

export const Control = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
`;

export const DropDown = styled(Stack)<IDropdownProps>`
  position: absolute;
  z-index: 10;
  top: 110%;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.dark5};
  ${({ position }) => positionMap[position]}
`;

export const Action = styled(Group)`
  padding: 6px 8px;
  border-radius: 4px;
  align-items: center;
  min-width: 110px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.bgSlate50};
  }
`;
