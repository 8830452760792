import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import Card from 'ui/card';
import { CardBody, CardHeader } from 'ui/card/Layout';

export const ThreadsBotCard = styled(Card)`
  position: fixed;
  z-index: 99;
  bottom: 80px;
  right: 20px;
  width: 100%;
  height: 600px;
  padding: 0;
  border-radius: 20px 25px;
  display: flex;
  flex-direction: column;
`;

export const ThreadsBotHeader = styled(CardHeader)`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 15px 20px;
`;

export const ThreadsBotBody = styled(CardBody)<{
  backgroundColor?: CSSProperties['backgroundColor'];
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  // height: 100%;
  width: 100%;
  max-height: fit-content;
  padding: 15px 20px;
  overflow: scroll;
  background-color=${({ backgroundColor }) => backgroundColor}
  flex-direction: column;
`;

export const ThreadsBotMessageContainer = styled.div<{
  senderIsMe: boolean;
  textColor?: CSSProperties['color'];
  backgroundColor?: CSSProperties['backgroundColor'];
  fontStyle?: CSSProperties['fontStyle'];
  noCursor?: boolean;
}>`
  color: ${({ textColor }) => textColor || 'auto'};
  padding: 10px;
  max-width: 85%;
  max-height: none;
  border-radius: ${({ senderIsMe }) => (senderIsMe ? '10px 10px 0 10px' : '10px 10px 10px 0')};
  height: fit-content;
  background-color: ${({ senderIsMe }) => (senderIsMe ? colors.blue5 : colors.bgSlate50)};
  margin-bottom: 15px;
  align-self: ${({ senderIsMe }) => (senderIsMe ? 'end' : 'start')};
  font-style: ${({ fontStyle }) => fontStyle || 'auto'};
  cursor: ${({ noCursor = false }) => (noCursor ? 'default' : 'auto')};
`;

export const ThreadsBotErrorContainer = styled(ThreadsBotMessageContainer)`
  border: 1px solid ${colors.red};
  background-color: ${colors.red3};
  font-family: monospace;
`;

export const ThreadsBotTextInputContainer = styled.div`
  display: flex;
`;
