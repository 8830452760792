import styled, { CSSProperties } from 'styled-components';
import colors from 'theme/colors';

interface IProps {
  backgroundColor?: CSSProperties['backgroundColor'];
  margin?: CSSProperties['margin'];
}

const Badge = styled.div<IProps>`
  padding: 4px 8px;
  background-color: ${({ backgroundColor }) => backgroundColor || colors.gray};
  border-radius: 4px;
  display: inline-block;
  margin: ${({ margin }) => margin || '0'};
`;

export default Badge;
