import routes from 'constants/routes';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import {
  useCheckInviteAcceptanceMutation,
  useContributorInviteUpdateMutation,
} from 'modules/contributors/contributorsApi';
import { useModal } from 'modules/modals/ModalProvider';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import colors from 'theme/colors';
import { Stack, Text, Group, Button, Spinner } from 'ui';
import { handleApiCall } from 'utils/helpers';

interface IProps {
  threadName: string | undefined;
  threadInviteId: number | undefined;
}

const ContributorInviteActions: FC<IProps> = ({ threadName, threadInviteId }) => {
  const { close } = useModal();
  const navigate = useNavigate();

  const [canAccept, setCanAccept] = React.useState(false);
  const [isInviteAlreadyAccepted, setIsInviteAlreadyAccepted] = React.useState(false);

  const [updateContributorInvite, { isLoading }] = useContributorInviteUpdateMutation();
  const [checkInviteAcceptance, { isLoading: isChecking }] = useCheckInviteAcceptanceMutation();

  const handleDeclineRequest = async () => {
    if (!threadInviteId) {
      showErrorMessage('Something went wrong. Please try again.');
    } else {
      const response = await updateContributorInvite({
        id: threadInviteId,
        accept: false,
      });
      handleApiCall(
        response,
        () => showErrorMessage('Could not decline request.'),
        () => {
          showSuccessMessage('Request declined.');
          close();
        }
      );
    }
  };

  const handleAcceptRequest = async () => {
    if (!threadInviteId) {
      showErrorMessage('Something went wrong. Please try again.');
    } else {
      const response = await updateContributorInvite({
        id: threadInviteId,
        accept: true,
      });
      handleApiCall(
        response,
        () => showErrorMessage('Could not accept request.'),
        () => {
          showSuccessMessage('Request accepted.');
          close();
        }
      );
    }
  };

  const checkInvite = async () => {
    if (!threadInviteId) return;
    const response = await checkInviteAcceptance({ threadInviteId });
    handleApiCall(
      response,
      ({ error }) => {
        if (error.status === 404) {
          setIsInviteAlreadyAccepted(true);
        } else {
          showErrorMessage('An error occurred while checking invite. Try again later.');
        }
      },
      ({ data }) => {
        setCanAccept(data.canAccept);
      }
    );
  };

  const handleLeaveThread = () => {
    navigate(routes.dashboard);
    close();
  };

  useEffect(() => {
    checkInvite();
  }, [threadInviteId]);

  if (isChecking) {
    return (
      <Stack style={{ height: 200 }} align="center" justify="center">
        <Spinner size="medium" color={colors.white} />
      </Stack>
    );
  }

  if (isInviteAlreadyAccepted) {
    return (
      <Stack style={{ height: 200 }} align="center" justify="center">
        <Text weight="600" size="lg">
          You have already accepted this invite
        </Text>
      </Stack>
    );
  }

  return (
    <Stack gap="25px">
      <Text weight="400" align="center" size="lg">
        You&apos;ve been invited to contribute to thread {threadName || ''}. Since you are part of
        too many threads, we couldn&apos;t satisfy that request.
      </Text>
      <Text weight="400" align="center" size="lg">
        Please leave one of your threads to join or decline the request.
      </Text>
      <Group gap="16px" align="center">
        {canAccept ? (
          <Button fluid onClick={handleAcceptRequest} disabled={isLoading}>
            Accept invite
          </Button>
        ) : (
          <Button fluid onClick={handleLeaveThread} disabled={isLoading}>
            Leave a thread
          </Button>
        )}
        <Button fluid onClick={handleDeclineRequest} color={colors.red} disabled={isLoading}>
          Decline request
        </Button>
      </Group>
    </Stack>
  );
};

export default ContributorInviteActions;
