export const sizeMap = {
  small: {
    strokeWidth: '2',
    width: '15',
  },
  medium: {
    strokeWidth: '2.5',
    width: '30',
  },
  large: {
    strokeWidth: '3',
    width: '50',
  },
};
