import React, { FC, ReactElement } from 'react';
import Header from '../modules/header/Header';
import { Container, Main } from './App';

interface IProps {
  children: ReactElement;
}

const WithHeader: FC<IProps> = ({ children }) => {
  return (
    <>
      <Header />
      <Main>
        <Container>{children}</Container>
      </Main>
    </>
  );
};

export default WithHeader;
