import styled from 'styled-components';
import colors from 'theme/colors';
import { IProps } from './types';

export const DropdownContainer = styled.div<IProps>`
  width: ${({ width }) => width || '100%'};
  border-radius: 4px;
`;
export const DropdownTitle = styled.div<IProps>`
  min-height: 43px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding: 0.6em 2em 0.5em 1em;
  border-radius: 4px;
  background-color: ${({ bg }) => bg || `${colors.blue2}`};
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};

  i {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5 ease-in-out;
  }
`;

export const DropdownLabel = styled.label<IProps>`
  font-size: 14px;
  span {
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.red};
  }
`;
export const DropdownListContainer = styled.div`
  position: relative;
  z-index: 10;
`;
export const DropdownList = styled.ul<IProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: ${({ bg }) => bg || `${colors.blue2}`};
  border: 2px solid ${({ bg }) => bg || `${colors.blue2}`};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  transition: all 0.5 ease-in-out;
`;
export const ListItem = styled.li`
  padding: 0.5em 2em 0.5em 1em;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    background-color: ${colors.blue};
  }
`;
