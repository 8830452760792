import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { resetAll } from 'app/api';
import { IPlayablePing, IPlayableState } from '../types';
import { playablePingApi } from '../api';

const initialState: Omit<IPlayableState, 'isPlayableFunctionalAvailable'> = {
  isIntegrationEnabled: false,
  isPlayableAvailable: false,
};

const playableSlice = createSlice({
  name: 'playable',
  initialState,
  reducers: {
    updatePlayableInfo: (state, { payload }: PayloadAction<IPlayablePing>) => ({
      ...state,
      isIntegrationEnabled: payload.integrationEnabled,
      isPlayableAvailable: payload.ping,
    }),
    resetPlayableInfo: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(resetAll, () => initialState)
      .addMatcher(
        playablePingApi.endpoints.pingPlayable.matchFulfilled,
        (state, { payload }: PayloadAction<IPlayablePing>) => ({
          ...state,
          isIntegrationEnabled: payload.integrationEnabled,
          isPlayableAvailable: payload.ping,
        })
      )
      .addMatcher(playablePingApi.endpoints.pingPlayable.matchRejected, (state) => ({
        ...state,
        isIntegrationEnabled: false,
        isPlayableAvailable: false,
      })),
});

export const playableReducer = playableSlice.reducer;
export const selectPlayable = (state: RootState) => state.playable;
export const { updatePlayableInfo, resetPlayableInfo } = playableSlice.actions;
