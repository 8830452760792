import React, { FC } from 'react';
import { Icon, Text } from 'ui';
import colors from 'theme/colors';
import { FileWrapper, CloseFile } from './Layout';

interface IFileProps {
  name: string;
  onRemove?: (fileName: string) => void;
}

const File: FC<IFileProps> = ({ name, onRemove }) => {
  return (
    <FileWrapper align="center" data-cy="file-wrapper">
      <Icon icon="AttachIcon" size="small" path={colors.blue7} />
      <Text noWrap color={colors.blue7} cypressAttribute="file-wrapper-text">
        {name}
      </Text>
      {onRemove && (
        <CloseFile onClick={() => onRemove(name)} data-cy="file-wrapper-close-file-btn">
          <Icon icon="PlusIcon" rotate="45deg" />
        </CloseFile>
      )}
    </FileWrapper>
  );
};

export default File;
