import React, { FC, useCallback } from 'react';
import {
  useGetNonActiveIntegrationsQuery,
  useUpdateIntegrationsMutation,
} from 'modules/integrations/integrationsApi';
import { Button, Group, Spinner, Stack, Text } from 'ui';
import colors from 'theme/colors';
import { isEmpty } from 'lodash';
import { AVAILABLE_INTEGRATIONS, INTEGRATION_LIST } from 'constants/integrations';
import { handleApiCall } from 'utils/helpers';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import * as Layout from '../Layout';

type TAvailableIntegrations = keyof typeof AVAILABLE_INTEGRATIONS;

export const AddIntegration: FC = () => {
  const { data, isLoading } = useGetNonActiveIntegrationsQuery();

  const [removeIntegration, { isLoading: isAddingIntegration }] = useUpdateIntegrationsMutation();

  const [approveIntegrationName, setApproveIntegrationName] =
    React.useState<TAvailableIntegrations>('' as TAvailableIntegrations);

  const isEmptyIntegrations = isEmpty(data);

  const integrationAvailableForActivation: TAvailableIntegrations[] = Object.keys(
    data || {}
  ) as TAvailableIntegrations[];

  const handleApproveIntegration = (integrationName: keyof typeof AVAILABLE_INTEGRATIONS) => {
    setApproveIntegrationName(integrationName);
  };

  const backToIntegrationList = useCallback(() => {
    setApproveIntegrationName('' as TAvailableIntegrations);
  }, []);

  const handleAddIntegration = async () => {
    const response = await removeIntegration({ [approveIntegrationName]: true });
    handleApiCall(
      response,
      () => showErrorMessage('Could not add integration'),
      () => {
        showSuccessMessage('Integration was added successfully');
        backToIntegrationList();
      }
    );
  };

  const hasApproveIntegration = !isEmpty(approveIntegrationName);

  if (isLoading) {
    return (
      <Layout.ListContainer fluid align="center" justify="center">
        <Spinner size="medium" color={colors.white} />
      </Layout.ListContainer>
    );
  }

  if (isEmptyIntegrations) {
    return (
      <Layout.ListContainer fluid align="center" justify="center">
        <Text weight="600" size="md" align="center" style={{ lineHeight: 1.5 }}>
          No integration available for activation. Contact{' '}
          <a href="mailto:support@authentise.zendesk.com" style={{ color: colors.blue }}>
            support@authentise.zendesk.com
          </a>{' '}
          to enable Threads integrations to Google, Sheets, Presentation, Docs etc.
        </Text>
      </Layout.ListContainer>
    );
  }

  if (hasApproveIntegration) {
    return (
      <Layout.ListContainer gap="25px">
        <Stack fluid align="center">
          {INTEGRATION_LIST[approveIntegrationName].icon}
        </Stack>
        <Text weight="600" align="center" size="lg" style={{ lineHeight: 'normal' }}>
          Are you sure you want to make active
          {` ${INTEGRATION_LIST[approveIntegrationName].title} `}
          integration?
        </Text>
        <Text weight="400" align="center" size="md" style={{ lineHeight: 'normal' }}>
          Performing this action will allow your users to connect to
          {` ${INTEGRATION_LIST[approveIntegrationName].title} `}
          integration.
        </Text>
        <Group gap="25px">
          <Button fluid onClick={handleAddIntegration} disabled={isAddingIntegration}>
            {isLoading ? <Spinner size="small" /> : 'Continue'}
          </Button>
          <Button color={colors.dark3} fluid onClick={backToIntegrationList}>
            Cancel
          </Button>
        </Group>
      </Layout.ListContainer>
    );
  }

  return (
    <Layout.IntegrationsList>
      {integrationAvailableForActivation.map((integrationName) => {
        const { title, icon } = INTEGRATION_LIST[integrationName];
        return (
          <Layout.IntegrationItem
            key={integrationName}
            connected={false}
            onClick={() => handleApproveIntegration(integrationName)}
          >
            <Layout.IntegrationItemButton connected={false}>
              {icon}
              <Text size="sm" weight="500" align="center" noWrap color={colors.white}>
                {title}
              </Text>
            </Layout.IntegrationItemButton>
          </Layout.IntegrationItem>
        );
      })}
    </Layout.IntegrationsList>
  );
};
