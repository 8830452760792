import React from 'react';
import { Text } from 'ui';

interface IProps {
  isPrivate?: boolean;
}
const PrivateThreadIndicator = ({ isPrivate = false }: IProps) => {
  return <Text size="xs">{isPrivate ? 'Private thread' : 'Public thread'}</Text>;
};

export default PrivateThreadIndicator;
