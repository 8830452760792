import styled from 'styled-components';
import { Button, Group, Text } from 'ui';

export const CopilotMessageContainer = styled.div`
  width: 100%;
  padding: 15px 24px;
`;

export const CopilotMessageContent = styled(Group)`
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.dark2};
  padding: 24px;
  padding-bottom: 40px;
  justify-content: center;

  border: ${({ theme }) => `1px solid ${theme.colors.orange}`};
  transition: background-color 0.3s ease;
  border-radius: 8px;
  flex: 1;
  gap: 20px;
  min-width: 0;
  position: relative;
  box-shadow: ${({ theme }) => `inset 0 0 10px 0 ${theme.colors.orange}`};
`;

export const CopilotHighlightedText = styled.span<{ fontStyle?: string }>`
  font-style: ${({ fontStyle }) => fontStyle || 'normal'};
  color: ${({ theme }) => theme.colors.orange};
  font-weight: bold;
`;

export const CopilotActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -14px;
  gap: 1rem;
`;

export const CopilotAction = styled(Button)`
  padding: 0.3rem 0.6rem;
  border-radius: 0.8rem;
  z-index: 10 !important;
`;

export const CopilotSuggestionPaginationContainer = styled.div`
  position: absolute;
  align-items: center;
  display: flex;
  right: 15px;
  bottom: 15px;
`;
