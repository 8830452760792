import React, { useState, useRef, useEffect } from 'react';
import { Icon, Text } from 'ui';
import { useOnClickOutside } from 'hooks';
import { IDropdownProps, IOption } from './types';
import DropdownTitle from './Title';
import * as Layout from './Layout';

const Dropdown: React.FC<IDropdownProps> = ({
  label,
  name,
  defaultValue,
  placeholder,
  options,
  error,
  badge,
  width,
  bg,
  required,
  onClick,
  disabled,
  cypressAttribute,
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<IOption | null>(defaultValue || null);
  const toggle = () => setIsOpen(!isOpen);

  const handleClick = (option: IOption) => () => {
    const { value } = option;
    setSelectedOption(option);
    setIsOpen(false);
    onClick?.(value);
  };

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  useEffect(() => {
    if (defaultValue === null) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Layout.DropdownContainer ref={dropdownRef} width={width}>
      {label && (
        <Layout.DropdownLabel
          htmlFor={name}
          data-cy={cypressAttribute && `${cypressAttribute}-label`}
        >
          {label}
          {required && <span>*</span>}
        </Layout.DropdownLabel>
      )}
      <Layout.DropdownTitle
        onClick={toggle}
        bg={bg}
        data-cy={cypressAttribute && `${cypressAttribute}-title`}
      >
        <DropdownTitle badge={badge} value={selectedOption?.value} placeholder={placeholder} />
        {!disabled && (
          <i aria-hidden="true">
            <Icon size="tiny" icon="ChevronDownIcon" />
          </i>
        )}
      </Layout.DropdownTitle>
      {error && (
        <Text size="xs" component="span" color="red">
          {error.message}
        </Text>
      )}
      {!disabled && isOpen && (
        <Layout.DropdownListContainer>
          <Layout.DropdownList bg={bg} data-cy={cypressAttribute && `${cypressAttribute}-list`}>
            {options.map((option: IOption) => (
              <Layout.ListItem
                key={option.value}
                onClick={handleClick(option)}
                data-cy={cypressAttribute && `${cypressAttribute}-list-item`}
              >
                {option.optionLabel}
              </Layout.ListItem>
            ))}
          </Layout.DropdownList>
        </Layout.DropdownListContainer>
      )}
    </Layout.DropdownContainer>
  );
};

export default Dropdown;
