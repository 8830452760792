import { ISystemMessagePayload } from '../../types';
import { CREATED, UPDATED } from './constants';

export const parseSystemMessage = (message: string): ISystemMessagePayload => {
  try {
    return JSON.parse(message);
  } catch (error) {
    return JSON.parse(message.replace(/[()"]|UUID/g, '').replaceAll("'", '"'));
  }
};

export const checkForAction = (message: string) =>
  [CREATED, UPDATED].some((substr) => message.includes(substr));
