import React from 'react';
import colors from 'theme/colors';
import { ThreadsBotErrorContainer, ThreadsBotMessageContainer } from 'ui/threadsBot/Layout';

interface IProps {
  id?: string;
  text: string;
  isMe: boolean;
  textColor?: string;
  noCursor?: boolean; // Auto determine cursor or always force to system default.
}

export const ThreadsBotError = ({ id, text }: Omit<IProps, 'isMe' & 'textColor'>) => {
  return (
    <ThreadsBotErrorContainer senderIsMe={false} id={id}>
      {text}
    </ThreadsBotErrorContainer>
  );
};

const ThreadsBotMessage = ({ id, isMe, text, textColor, noCursor = false }: IProps) => {
  return (
    <ThreadsBotMessageContainer id={id} senderIsMe={isMe} textColor={textColor} noCursor={noCursor}>
      {text}
    </ThreadsBotMessageContainer>
  );
};

export default ThreadsBotMessage;
