import React, { FC } from 'react';
import styled from 'styled-components';
import Group from '../group';
import Text from '../text';
import colors from '../../theme/colors';

interface IStepperProps {
  active?: number;
  steps: string[];
}

const Step = styled.div<{ isActive: boolean }>`
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.primaryColor : theme.colors.dark3};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Stepper: FC<IStepperProps> = ({ active = 0, steps }) => {
  return (
    <Group gap="40px">
      {steps.map((step, index) => (
        <Group key={step} align="center">
          <Step isActive={index === active}>
            <Text>{index + 1}</Text>
          </Step>
          <Text color={index !== active ? colors.dark4 : undefined}>{step}</Text>
        </Group>
      ))}
    </Group>
  );
};

export default Stepper;
