import { ChangeEventHandler } from 'react';

export enum ESize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export type TSize = 'sm' | 'md' | 'lg' | 'xl';

export interface ICheckBoxProps {
  size?: TSize;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  name: string;
  value?: string | number;
  cypressAttribute?: string;
}

export interface ICheckProps {
  size?: TSize;
}
