/**
 * @description Compresses images whose widths are larger than specified, or default of 250px
 */
export const compressImage = async (avatar: File, maxWidth = 250) => {
  const img = new Image();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const imgLoad = new Promise<HTMLImageElement>((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = reject;
  });

  const reader = new FileReader();
  reader.readAsDataURL(avatar);
  reader.onload = () => {
    img.src = reader.result as string;
  };

  await imgLoad;

  // Calculate new dimensions
  let width = img.width;
  let height = img.height;

  if (width > maxWidth) {
    height *= maxWidth / width;
    width = maxWidth;
  }

  if (ctx) {
    // Draw the resized image on the canvas
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);

    return new Promise<File>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const resizedImageFile = new File([blob], avatar.name, {
            type: 'image/jpeg',
            lastModified: Date.now(),
          });
          resolve(resizedImageFile);
        } else {
          reject(new Error('Failed to create blob'));
        }
      }, 'image/jpeg');
    });
  }

  throw Error("Context couldn't be found.");
};
