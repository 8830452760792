import styled from 'styled-components';
import { Stack } from 'ui';

export const PlyableAnnotationContentHolder = styled(Stack)`
  flex-flow: row nowrap;
  padding: 16px;
  border-radius: 8px;
  max-height: 345px;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.dark2};
  border: 1px solid #f583ff;
  box-shadow: inset 0px 0px 10px 0px #f583ff;
  overflow: hidden;
`;
