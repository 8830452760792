import { useModal } from 'modules/modals/ModalProvider';
import React, { FC } from 'react';
import colors from 'theme/colors';
import { Button, Group, Spinner, Stack, Text } from 'ui';
import { useContributorLeaveThreadMutation } from 'modules/contributors/contributorsApi';
import { showErrorMessage, showSuccessMessage } from 'modules/alert/utils';
import { handleApiCall } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import routes from 'constants/routes';

interface IProps {
  threadName: string;
  threadId: string | undefined;
}

const LeaveThread: FC<IProps> = ({ threadName, threadId }) => {
  const { close } = useModal();
  const navigate = useNavigate();

  const [leaveThread, { isLoading }] = useContributorLeaveThreadMutation();

  const onLeaveThread = async () => {
    if (!threadId) {
      showErrorMessage('Wrong thread id. Please try again.');
      return;
    }
    const response = await leaveThread({ threadId });
    handleApiCall(
      response,
      () => showErrorMessage('Could not leave thread. Please try again.'),
      () => {
        showSuccessMessage(`You have left the thread "${threadName}".`);
        navigate(routes.dashboard);
        close();
      }
    );
  };
  return (
    <Stack fluid align="center" justify="center" gap="15px">
      <Text size="lg" style={{ lineHeight: 'normal' }} align="center">
        Are you sure you want to leave the thread &quot;{threadName}&quot; ? If you do this you
        won&apos;t be able to access its comments, events or resources anymore.
      </Text>
      <Group fluid gap="15px">
        <Button type="filled" onClick={onLeaveThread} fluid disabled={isLoading}>
          {isLoading ? <Spinner size="small" /> : `Yes, I'm sure`}
        </Button>
        <Button type="filled" color={colors.dark2} onClick={close} fluid>
          No, I want to stay
        </Button>
      </Group>
    </Stack>
  );
};

export default LeaveThread;
