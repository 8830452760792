import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 120px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Body = styled.div`
  margin-top: 10px;
  width: 100%;
  max-width: 600px;
  height: 100%;
`;
