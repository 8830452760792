import React from 'react';
import LoginLayout from 'layout/Login';
import { Title } from 'ui';
import colors from 'theme/colors';
import Form from './Form';

const ResetPassword = () => {
  return (
    <LoginLayout>
      <>
        <div>
          <Title heading="h1" style={{ marginBottom: '30px' }}>
            Reset Password
          </Title>
          <Title style={{ fontSize: '16px' }} heading="h5" color={colors.gray1}>
            To reset your password, please type in your new password and confirm it.
          </Title>
        </div>
        <Form />
      </>
    </LoginLayout>
  );
};

export default ResetPassword;
