import styled from 'styled-components';
import { Button } from 'ui';

export const FilterWrapper = styled.div`
  position: relative;
`;

export const FilterButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.blue2};
  padding: 5px;

  &:hover {
    opacity: 0.8;
  }
`;

export const Filter = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralBlue100};
  position: absolute;
  right: 0;
  top: 120%;
  z-index: 5;
  width: 395px;
  padding: 24px;
  border-radius: 8px;
`;
