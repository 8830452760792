import React, { FC } from 'react';
import styled from 'styled-components';
import { IMember } from './types';
import Group from '../group';
import { Avatar } from '../index';
import Text from '../text';

const Wrapper = styled(Group)`
  padding: 8px;
  width: max-content;
  border-radius: 4px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.bgSlate50};
  }
`;

const Member: FC<IMember> = ({ lastName, firstName, avatar }) => {
  return (
    <Wrapper align="center">
      <Avatar size="small" src={avatar} />
      <Text>{firstName}</Text>
      <Text>{lastName}</Text>
    </Wrapper>
  );
};

export default Member;
