import React, { CSSProperties, ReactElement, forwardRef } from 'react';
import * as Layout from './Layout';

interface IProps {
  children: ReactElement | ReactElement[];
  padding?: string;
  onClick?: (data: any) => any;
  style?: CSSProperties;
  cursorPointer?: boolean;
  [key: string]: any;
}

/**
 * Base card component for multi-purpose use
 */
const Card = forwardRef(
  ({ children, padding, onClick, style, cursorPointer = false, ...restProps }: IProps, ref) => {
    return (
      <Layout.Card
        ref={ref}
        style={style}
        onClick={onClick}
        padding={padding}
        cursorPointer={cursorPointer}
        {...restProps}
      >
        {children}
      </Layout.Card>
    );
  }
);

export default Card;
