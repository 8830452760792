import React from 'react';
import { EEventStatus } from 'constants/events';
import { EThreadStatuses } from 'types/index';
import styled from 'styled-components';
import { IBadgeProps } from 'modules/threads/types';
import { STATUS_COLORS, STATUS_NAMES } from './constants';

interface IStatusBadgeProps extends IBadgeProps {
  status: EThreadStatuses | EEventStatus;
  text?: boolean;
  radius?: string;
}

const StatusCircleIcon = styled.span<IStatusBadgeProps>`
  padding-left: 16px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: ${({ radius }) => radius || '8'}px;
    height: ${({ radius }) => radius || '8'}px;
    border-radius: 50%;
    background-color: ${({ status }) => STATUS_COLORS[status]};
  }
`;

const StatusBadgeWithCircleIcon: React.FC<IStatusBadgeProps> = ({
  status,
  text = true,
  radius,
}) => {
  return (
    <StatusCircleIcon status={status} radius={radius}>
      {text ? STATUS_NAMES[status] : null}
    </StatusCircleIcon>
  );
};

export default StatusBadgeWithCircleIcon;
