import { api } from 'app/api';
import { toFormData } from 'utils/toFormData';
import { ISignUpResponse } from 'modules/auth/types';
import { IRegisterMutation, ISelfLinkRegisterMutation } from './types';

export const signupApi = api.injectEndpoints({
  endpoints: (build) => ({
    register: build.mutation<ISignUpResponse, IRegisterMutation>({
      query: (payload) => ({
        url: `/invite-sign-up/`,
        method: 'POST',
        body: payload.avatar ? toFormData(payload) : payload,
      }),
    }),
    selfLinkRegister: build.mutation<ISignUpResponse, ISelfLinkRegisterMutation>({
      query: (payload) => ({
        url: `/self-sign-up/`,
        method: 'POST',
        body: payload.avatar ? toFormData(payload) : payload,
      }),
    }),
  }),
});

export const { useRegisterMutation, useSelfLinkRegisterMutation } = signupApi;
