// add here other labels for modal windows
export const MODAL_STATUS_LABELS_TEXT = {
  VERSIONED: 'versioned',
  SYNCED: 'synced',
} as const;

// here you define what color will be used for each label
export const MODAL_STATUS_LABELS_COLORS = {
  [MODAL_STATUS_LABELS_TEXT.VERSIONED]: '#1479F0',
  [MODAL_STATUS_LABELS_TEXT.SYNCED]: '#11B667',
};
