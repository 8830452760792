import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IPaginatedResponse } from 'types';
import { IUser } from 'types/user';
import { userApi } from './userApi';

interface IUsersState {
  list: IUser[];
  total: number;
}

export const initialState: IUsersState = {
  list: [],
  total: 0,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    deleteUser: (state, { payload }: PayloadAction<string>) => {
      state.list = state.list.filter((user) => user.id !== payload);
    },
    reset: () => initialState,
  },
  extraReducers: (builder) =>
    builder.addMatcher(
      userApi.endpoints.getUsers.matchFulfilled,
      (state, { payload }: PayloadAction<IPaginatedResponse<IUser>>) => {
        state.list.push(...payload.results);
        state.total = payload.count;
      }
    ),
});

export const selectUsers = (state: RootState): IUser[] => state.users.list;
export const selectTotal = (state: RootState): number => state.users.total;

export const { deleteUser, reset } = usersSlice.actions;
export default usersSlice.reducer;
