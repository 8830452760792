import React, { FC } from 'react';
import Select from 'react-select';
import { integrationSelectStyles } from './styles';

interface IProps {
  options: Array<{ value: string; label: string }>;
  onChange: (option: { label: string; value: string }) => void;
  isDisabled?: boolean;
}

const PlayableIntegrationSelect: FC<IProps> = ({ options, isDisabled = false, onChange }) => {
  return (
    <Select
      placeholder="Select mold type"
      isDisabled={isDisabled}
      options={options}
      isSearchable={false}
      isMulti={false}
      menuPortalTarget={document.body}
      styles={integrationSelectStyles}
      onChange={(option) => onChange(option as { label: string; value: string })}
      menuPlacement="auto"
      className="integration-select-container"
      classNamePrefix="integration-react-select"
    />
  );
};

export default PlayableIntegrationSelect;
