import React, { FC } from 'react';
import styled from 'styled-components';
import Group from '../group';
import Menu from '../menu';
import colors from '../../theme/colors';
import Text from '../text';
import { IMember } from './types';
import Member from './Member';

const Control = styled(Group)`
  background-color: ${({ theme }) => theme.colors.dark1};
  height: 27px;
  width: 27px;
  border-radius: 50%;
`;

interface IRestMembersProps {
  list: IMember[];
  disabled?: boolean;
}

const RestMembers: FC<IRestMembersProps> = ({ list, disabled }) => {
  return (
    <Menu
      disabled={disabled}
      style={{ marginLeft: `-10px` }}
      Item={Member}
      data={list}
      cypressAttribute="rest-members-menu"
    >
      <Control align="center" justify="center">
        <Text
          size="sm"
          color={colors.dark4}
          cypressAttribute="rest-members-text"
        >{`+${list.length}`}</Text>
      </Control>
    </Menu>
  );
};

export default RestMembers;
