import React from 'react';
import { Divider, Group, Stack, Title } from 'ui';
import LogoImg from 'assets/icons/Logo.svg';
import SignupForm from './components/SignupForm';

const Signup = () => {
  return (
    <Stack gap="50px" fluid>
      <Group justify="space-between" align="center">
        <Title heading="h1">Sign up</Title>
        <img width={150} src={LogoImg} alt="logo" />
      </Group>
      <Stack gap="30px" fluid>
        <Divider />
        <SignupForm />
      </Stack>
    </Stack>
  );
};

export default Signup;
